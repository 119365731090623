import {configure, makeObservable, observable} from 'mobx';
import FirestoreService from '../services/FirestoreService';
import {IUser} from '../models/users/IUser';
import {GridSortModel} from '@mui/x-data-grid';
import {ILabel} from '../models/users/ILabel';
import {logger} from '../services/LoggerService';

configure({
    enforceActions: "never",
});

export default class UsersStore {
    isLoading: boolean = false;
    isLoadingRecognitions: boolean = false;
    totalUsersCount: number = 0;
    page: number = 0;
    prevPage: number = 0;
    pageSize: number = 10;
    sortModel: GridSortModel | undefined = undefined;
    users: IUser[] = [];
    openUserDialog: boolean = false;
    lazyOpenUserDialog: boolean = false;
    searchingUsers: boolean = false;
    user: IUser | undefined = undefined;
    tempUser: IUser | undefined = undefined;

    regionLabels: ILabel[] = [];
    medOrgLabels: ILabel[] = [];
    doctorLabels: ILabel[] = [];

    constructor() {
        logger.debug('+++ UsersStore constructor() +++');
        makeObservable(this, {
            isLoading: observable,
            isLoadingRecognitions: observable,
            totalUsersCount: observable,
            users: observable,
            user: observable,
            tempUser: observable,
            page: observable,
            pageSize: observable,
            openUserDialog: observable,
            lazyOpenUserDialog: observable,
            searchingUsers: observable,
        });
    }

    public setUsers = (users: IUser[]) => {
        logger.debug("+++ UsersStore -> setUsers() +++ users:", users);
        this.users = users;
    }

    public setPageSize = (pageSize: number) => {
        this.pageSize = pageSize;
        this.setPage(0);
    }

    public setPage = async (page: number) => {
        logger.debug("+++ UsersStore -> setPage() +++ page:", page);
        if (!this.searchingUsers) {
            this.prevPage = JSON.parse(JSON.stringify(this.page));
            this.page = page;
            await FirestoreService.loadUsers(this);
        } else {
            this.page = page;
        }
    }

    public setSortModel = async (model: GridSortModel | undefined) => {
        logger.debug("+++ UsersStore -> setSortModel() +++ model:", model);
        this.sortModel = model;
        if (!this.searchingUsers) {
            await this.reloadPage();
        }
    }

    private reloadPage = async () => {
        logger.debug("+++ UsersStore -> reloadPage() +++ this.page:", this.page);
        await FirestoreService.loadUsers(this);
    }

    public setOpenUserDialog = (open: boolean, user?: IUser) => {
        this.openUserDialog = open;
        this.user = user;
        this.tempUser = undefined;
        if (!open) {
            setTimeout(() => this.lazyOpenUserDialog = open, 500);
        } else {
            this.lazyOpenUserDialog = open;
        }
    }
}
