import React from 'react';
import { styled } from '@mui/material/styles';
import {Dialog} from '@mui/material';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        paddingLeft: '16px',
        paddingRight: '16px',
        paddingTop: '16px',
        paddingBottom: '8px',
        backgroundColor: '#e8f3f5'
    },
    "& .MuiDialogActions-root": {
        paddingLeft: '16px',
        paddingRight: '16px',
    }
}));
