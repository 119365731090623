import React from 'react';
import { styled } from '@mui/material/styles';
import {Checkbox} from '@mui/material';

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    '&.MuiCheckbox-root': {
        padding: '0 !important',
        marginLeft: 0,
    },
}));
