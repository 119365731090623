import {ILabel} from '../models/users/ILabel';
import {logger} from '../services/LoggerService';
import {IUserWorkspace} from "../models/users/IUserWorkspace";


export const loadLabels = async (file: string): Promise<ILabel[]> => {
    let loadedJson: ILabel[] = [];
    const result = await fetch(file, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
    })
        .then(response => {
            return response.text();
        })
        .then((content) => {
            try {
                if (!!content) {
                    loadedJson = JSON.parse(content);
                }
                loadedJson = loadedJson || [];
            } catch (error) {
                loadedJson = [];
            }
            return loadedJson;
        })
        .catch((err) => {
            logger.error(err);
            loadedJson = [];
            return loadedJson;
        });

    return result;
}


export const getWorkspacesDTO = (workspaces: number[], userId: Number): IUserWorkspace[] => {
    return  workspaces.map((checkedWorkspace) => {
        return {
            userId: userId,
            workspaceId: checkedWorkspace,
        } as IUserWorkspace;
    });
}