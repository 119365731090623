import React, {Fragment} from 'react';
import './App.scss';
import AppHeader from './app/components/common/header/AppHeader';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import UsersPage from './app/components/users/UsersPage';
import HomePage from './app/components/HomePage';
import {observer} from 'mobx-react';
import {rootStore, RootStore} from './app/stores/root';
import SignInPage from './app/components/SignInPage';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthGuard from './app/components/AuthGuard';
import StatsPage from "./app/components/stats/StatsPage";
import {ReportStaticStats} from "./app/components/stats/reports/ReportStaticStats";
import {ReportDynamicStats} from "./app/components/stats/reports/ReportDynamicStats";
import {DocumentStaticStats} from "./app/components/stats/documents/DocumentStaticStats";
import {DocumentDynamicStats} from "./app/components/stats/documents/DocumentDynamicStats";


const App = observer(({store}: {store: RootStore}) => {

    const navigate = useNavigate();
    store.navigate = navigate;
    rootStore.navigate = navigate;
    return (
        <Fragment>
            <Routes>
                <Route element={<AppHeader store={rootStore.authStore} />} >
                    <Route path="/" element={<HomePage />} />

                    <Route path="users" element={
                        <AuthGuard store={rootStore.authStore}>
                            <UsersPage store={rootStore.usersStore} />
                        </AuthGuard>
                    } />

                    <Route path="stats" element={
                        <AuthGuard store={rootStore.authStore}>
                            <StatsPage />
                        </AuthGuard>
                    }>

                        <Route path="report-static" element={<ReportStaticStats />} />
                        <Route path="report-dynamic" element={<ReportDynamicStats />} />
                        <Route path="document-static" element={<DocumentStaticStats />} />
                        <Route path="document-dynamic" element={<DocumentDynamicStats />} />

                    </Route>

                    <Route path="login" element={
                        <SignInPage store={rootStore.authStore} />
                    } />

                    <Route path="*" element={<Navigate replace to="/" />} />

                </Route>
            </Routes>

            <ToastContainer
                position="bottom-right"
                autoClose={7000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
        </Fragment>
    );
});

export default App;
