export const globalTheme = {
    palette: {
        primary: {
            main: '#0f7fa5',
            // darker: '#0B5F79'
        },
        secondary: {
            main: '#A950B3',
        },
        warning: {
            main: '#ff1744',
        },
        success: {
            main: '#54b399',
        }
    },
};
