import {ILabel} from "../models/users/ILabel";
import {toast} from "react-toastify";
import {ENV_CONFIG} from "../../getEnvConfig";

export default class NamesResolveService {

    static readonly NAMES_RESOLVE_REGIONS_URL: string = ENV_CONFIG.API_NR_URL + "/" + ENV_CONFIG.API_NR_REGION_ENDPOINT;
    static readonly NAMES_RESOLVE_OIDS_URL: string = ENV_CONFIG.API_NR_URL + "/" + ENV_CONFIG.API_NR_OID_ENDPOINT;
    static readonly NAMES_RESOLVE_DOCTORS_URL: string = ENV_CONFIG.API_NR_URL + "/" + ENV_CONFIG.API_NR_DOCTOR_ENDPOINT;

    public static async getRegionLabels(regionIds: string[]): Promise<ILabel[]> {
        return this.getLabels(regionIds, this.NAMES_RESOLVE_REGIONS_URL);
    }

    public static async getMedOrgLabels(regionIds: string[]): Promise<ILabel[]> {
        return this.getLabels(regionIds, this.NAMES_RESOLVE_OIDS_URL);
    }

    public static async getDoctorLabels(regionIds: string[]): Promise<ILabel[]> {
        return this.getLabels(regionIds, this.NAMES_RESOLVE_DOCTORS_URL);
    }

    private static async getLabels(regionIds: string[], url: string): Promise<ILabel[]> {

        return await fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({"codes": regionIds})

        })
            .then(response => response.json())
            .then(response => {

                const mappingsEntries: Array<Array<string>> = Object.entries(response?.mappings || {});
                if (mappingsEntries.length) {
                    return mappingsEntries.map((value: string[]) => {
                        return {
                            'code': value[0],
                            'value': value[1]
                        }
                    })
                } else {
                    throw new Error("response.mapping is empty")
                }
            })
            .catch((error) => {
                console.log('names-resolve Promise error: ', error);
                toast("Ошибка загрузки названий ресурсов", {type: 'error'})
                return [];
            })
    }
}