import {StyledCheckbox} from "../StyledCheckbox";
import React from "react";
import {ListItemIcon, ListItemText} from "@mui/material";

interface CustomCheckboxProps {
    isChecked: boolean,
    label: string,
    id: string,
}

export const CustomCheckbox = ({isChecked, label, id}: CustomCheckboxProps) => {

    return <>
        <ListItemIcon sx={{minWidth: '30px'}}>
            <StyledCheckbox
                size={'small'}
                edge="start"
                checked={isChecked}
                tabIndex={-1}
                disableRipple
                inputProps={{'aria-labelledby': id}}
            />
        </ListItemIcon>
        <ListItemText id={id} primary={label}/>
    </>
}