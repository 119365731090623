import * as React from 'react';
import './AppHeader.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import AuthStore from '../../../stores/auth';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import {Fragment} from 'react';
import medicbkLogo from '../../../../assets/medicbk.png';
import {Trans, useTranslation} from "react-i18next";


const pages = [
    {
        title: 'header-links.users',
        route: '/users',
        disabled: false,
    },
    {
        title: "Статистика",
        route: "/stats/report-static",
        disabled: false
    }
];

const AppHeader = observer(({store}: {store: AuthStore}) => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
    };

    const navigate = useNavigate();
    const location = useLocation();

    store.rootStore.navigate = navigate;

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const navigateToLogin = () => {
        store.rootStore.navigate!('/login');
    };
    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleNavMenu = (route: string) => {
        handleCloseNavMenu();
        navigate(route);
    };

    const isCurrentRoute = (route: string): boolean => {
        return location.pathname === route;
    };

    const callSignOut = (): void => {
        sessionStorage.removeItem('x_auth_token');
        sessionStorage.removeItem('username');
        store.token = undefined;
        store.username = undefined;
    };

    return (
        <>
        <AppBar position="sticky">
            <Container maxWidth={false} sx={{paddingRight: '0 !important', paddingLeft: '20px !important'}}>
                <Toolbar disableGutters>

                    <Avatar sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} alt="Medic BK" src={medicbkLogo} />

                    <Button
                        onClick={() => handleNavMenu('/')}
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            mr: 4,
                        }}
                    >
                        <Typography
                            variant="h6"
                            noWrap
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'white',
                                textDecoration: 'none',
                            }}
                        >
                            MEDIC-BK
                        </Typography>
                    </Button>

                    <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }, marginLeft: '-12px', }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page, index) => (
                                <MenuItem key={index} onClick={() => handleNavMenu(page.route)} disabled={page.disabled}>
                                    <Typography textAlign="center">{t(page.title)}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Avatar sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} alt="Medic BK" src={medicbkLogo} />

                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'none', sm: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        MEDIC-BK
                    </Typography>
                    <Box id={'EXTENDER-1'} sx={{flexGrow: 1, display: {sm: 'flex', md: 'none'}}}></Box>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {!!store.isAuth &&
                            pages.map((page, index) => (
                                <Button
                                    key={index}
                                    onClick={() => handleNavMenu(page.route)}
                                    sx={{
                                        my: 1,
                                        mr: 2,
                                        color: 'white',
                                        bgcolor: isCurrentRoute(page.route) ? 'primary.light' : 'primary',
                                        display: 'block'
                                    }}
                                    disabled={page.disabled}
                                    classes={{ disabled: 'disabledButton' }}
                                >
                                    {t(page.title)}
                                </Button>
                            ))
                        }
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {!!store.isAuth &&
                            <Fragment>
                                <MenuItem>
                                    <Typography textAlign="center">
                                        {store.username}
                                    </Typography>
                                </MenuItem>
                                {/*<MenuItem>*/}
                                {/*    <button onClick={() => changeLanguage("en")}>EN</button>*/}
                                {/*    <button onClick={() => changeLanguage("ru")}>RU</button>*/}
                                {/*</MenuItem>*/}

                                <MenuItem onClick={() => callSignOut()}>
                                    <Typography textAlign="center" sx={{textTransform: 'uppercase', mr: 1}}>
                                        Выход
                                    </Typography>
                                    <LogoutIcon className={'signButton'} />
                                </MenuItem>

                            </Fragment>
                        }

                        {!store.isAuth &&
                            <Fragment>
                                <MenuItem onClick={navigateToLogin}>
                                    <Typography textAlign="center" sx={{textTransform: 'uppercase', mr: 1}}>
                                        Вход
                                    </Typography>
                                    <LoginIcon className={'signButton'} />
                                </MenuItem>
                            </Fragment>
                        }
                    </Box>

                </Toolbar>
            </Container>
        </AppBar>
        <Outlet />
        </>
    );
});

export default AppHeader;
