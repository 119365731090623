import {LOG_LEVEL} from '../../environment';
import {LOG_DEBUG} from '../../environment';
import moment from 'moment';

/** Signature of a logging function */
export interface LogFn {
    (message?: any, ...optionalParams: any[]): void;
}

/** Basic logger interface */
export interface Logger {
    log: LogFn;
    warn: LogFn;
    error: LogFn;
    debug: LogFn;
}

/** Log levels */
export type LogLevel = 'LOG' | 'WARN' | 'ERROR' | 'DEBUG';

// const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => {};
const NO_OP: LogFn = (...optionalParams: any[]) => {};

/** Logger which outputs to the browser console */
export class LoggerService implements Logger {
    readonly log: LogFn;
    readonly warn: LogFn;
    readonly error: LogFn;
    readonly debug: LogFn;

    constructor(options?: { level? : LogLevel, logDebug: boolean }) {
        const { level, logDebug } = options || {};

        this.error = console.error.bind(console);

        if (level === 'ERROR') {
            this.warn = NO_OP;
            this.log = NO_OP;
            this.debug = NO_OP;

            return;
        }

        this.warn = console.warn.bind(console);

        if (level === 'WARN') {
            this.log = NO_OP;
            this.debug = NO_OP;

            return;
        }

        const getPrefix = (): string => {
            return  '%c' + moment().format('YYYY.MM.DD HH:mm:ss.SSS ->');
        }

        if (logDebug) {
            this.debug = console.log.bind(console, getPrefix(), 'color: #ff8080;');
        } else {
            this.debug = NO_OP;
        }

        if (level === 'DEBUG') {
            this.log = NO_OP;
            return;
        }

        this.log = console.log.bind(console);
    }
}

export const logger = new LoggerService({ level: LOG_LEVEL, logDebug: LOG_DEBUG });
