import React from 'react';
import AuthStore from '../stores/auth';
import {observer} from 'mobx-react';
import {Navigate, useLocation} from 'react-router-dom';

const AuthGuard = observer(({store, children}: {store: AuthStore, children: any}) => {
    const location = useLocation();
    return  store.isAuth ? children : <Navigate to="/login" state={{ from: location }} replace />;
});

export default AuthGuard;
