import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {globalTheme} from './globalTheme';
import {CssBaseline, StyledEngineProvider} from '@mui/material';
import GlobalStyle from './globalStyles';
import {BrowserRouter} from 'react-router-dom';
import {rootStore} from './app/stores/root';
import {ruRU as datePickersRU} from '@mui/x-date-pickers/locales';
import {ruRU} from '@mui/x-data-grid';
import {ruRU as coreRuRU} from '@mui/material/locale';
import {ApolloClient, ApolloLink, ApolloProvider, createHttpLink, from, InMemoryCache,} from '@apollo/client';

import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {logger} from './app/services/LoggerService';
import {toast} from 'react-toastify';
import "./i18next";

import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/ru';
import {checkEnvConfig, ENV_CONFIG} from "./getEnvConfig";


const container = document.getElementById('root')!;
const root = createRoot(container);
const theme = createTheme(
    {...globalTheme},
    ruRU,
    datePickersRU,
    coreRuRU,
);


const getInitRender = () => {

    const httpLink: ApolloLink = createHttpLink({
        uri: ENV_CONFIG.API_PROTOCOL + "://" + ENV_CONFIG.API_GRAPHQL_HOST + ":" + ENV_CONFIG.GRAPHQL_PORT +  "/" + ENV_CONFIG.GRAPHQL_ENDPOINT
    });

    const authLink: ApolloLink = setContext((_, { headers }) => {
        const token = rootStore.authStore.token;
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            }
        }
    });

    const errorLink: ApolloLink =  onError(({
                                                networkError,
                                                graphQLErrors ,
                                                response,
                                                operation,
                                                forward}) => {

        logger.error("[GraphQL onError]: networkError:", networkError);
        logger.error("[GraphQL onError]: graphQLErrors:", graphQLErrors);
        logger.error("[GraphQL onError]: response:", response);
        logger.error("[GraphQL onError]: operation:", operation);
        logger.error("[GraphQL onError]: forward:", forward);

        if (graphQLErrors) {
            logger.error("[GraphQL error]: graphQLErrors:", graphQLErrors);
        }

        if (networkError) {
            logger.error("[Network error]:", networkError);
            toast(`Сетевая ошибка: ${networkError.message}`, {type: 'error'});
        }

    });

    const client = new ApolloClient({
        link: from([
            errorLink,
            authLink,
            httpLink,
        ]),
        cache: new InMemoryCache(),

        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
            query: {
                fetchPolicy: 'no-cache',
                errorPolicy: 'all',
            },
        },
    });

    return <React.StrictMode>
        <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <ApolloProvider client={client}>
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ru"}>
                            <CssBaseline />
                            <GlobalStyle />
                            <Suspense fallback={<div>Loading...</div>}>
                                <App store={rootStore}/>
                            </Suspense>
                        </LocalizationProvider>
                    </ThemeProvider>
                </ApolloProvider>
            </StyledEngineProvider>
        </BrowserRouter>
    </React.StrictMode>;
}

rootStore.authStore.token = sessionStorage.getItem('x_auth_token') || undefined;
rootStore.authStore.username = sessionStorage.getItem('username') || undefined;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

root.render(checkEnvConfig(ENV_CONFIG) ? getInitRender() : <div>
    <span>ENVIRONMENT CONFIG IS INVALID</span>
    <pre><code>{JSON.stringify(ENV_CONFIG, null, 4)}</code></pre>
    </div>
);
