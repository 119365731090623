import React, {ChangeEvent, memo, SyntheticEvent, useCallback, useEffect, useRef} from 'react';
import {
    Box,
    Chip,
    CircularProgress,
    CssBaseline,
    debounce,
    FormControl,
    InputAdornment,
    ListItem,
    TextField,
} from '@mui/material';
import 'moment/locale/ru';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {ITreeData} from '../../models/users/ITreeData';
import Grid2 from '@mui/material/Unstable_Grid2';
import {bgFade, copyData} from '../../utils/general';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {ResourceTypeEnum} from '../../models/enums/ResourceTypeEnum';
import TreeItemView from './TreeItemView';
import Typography from '@mui/material/Typography';
import ClearIcon from "@mui/icons-material/Clear";
import ResourceTreeService from "../../services/ResourceTreeService";

interface DataTreeViewProps {
    treeItems: ITreeData,
    isLoadingTreeData: boolean,
    selectedTreeItems: ITreeData[],
    onChangeSelected: (selected: ITreeData[]) => void,
}

const RecursiveTreeView = ({treeItems, isLoadingTreeData, selectedTreeItems, onChangeSelected}: DataTreeViewProps) => {

    const SEARCH_LIMIT = 5;
    let tempExpanded: string[] = [];

    const parentTreeViewRef = useRef<HTMLDivElement | null>(null);
    const [searchText, setSearchText] = React.useState<string>('');
    const [treeItemsData, setTreeItemsData] = React.useState<ITreeData>(copyData(treeItems));
    const [selected, setSelected] = React.useState<ITreeData[]>([]);
    const [isSearching, setIsSearching] = React.useState<boolean>(false);

    const debouncedCallSearch = debounce((parameter) => {
        callSearch(parameter);
    }, 1500);

    useEffect(() => {
        if (treeItems.children?.length) {
            setIsSearching(true);
            debouncedCallSearch(searchText);
        }
    }, [searchText]);

    useEffect(() => {
        setTreeItemsData(treeItems);
        setExpanded(getExpanded(treeItems));
    }, [treeItems]);

    useEffect(() => {
        if ((selected || []).length !== (selectedTreeItems || []).length && !isLoadingTreeData) {
            setSelected(ResourceTreeService.prepareTreeNodeIds(treeItems, selectedTreeItems));
        }
    }, [selectedTreeItems, treeItems]);

    useEffect(() => {
        onChangeSelected(selected);
    }, [selected]);

    // Get all children from the current node.
    const getAllChild = (childNode: ITreeData | null, collectedNodes: ITreeData[] = []): ITreeData[] => {
        if (childNode === null) return collectedNodes;

        collectedNodes.push(childNode);

        if (Array.isArray(childNode.children)) {
            for (const node of childNode.children) {
                getAllChild(node, collectedNodes);
            }
        }

        return collectedNodes;
    }

    const getExpanded = (data: ITreeData): string[] => {

        if (data.children && data.children.length === 1) {
            return [data.children[0].id];
        } else {
            return [];
        }
    }

    const [expanded, setExpanded] = React.useState<string[]>([]);

    const getOnChange = useCallback((node: ITreeData) => (event: ChangeEvent<HTMLInputElement>) => {

        let array;

        if (event.currentTarget.checked) {

            array = [...selected];
            const toBeAdded = [node, ...ResourceTreeService.getParentNodes(node, treeItemsData)];
            for (let addedNode of toBeAdded) {
                if (!array.find(n => n.id == addedNode.id)) {
                    array.push(addedNode);
                }
            }

        } else {
            array = selected.filter((value) => node.id !== value.id);
        }

        setSelected(array);
    }, [selected, treeItemsData]);

    const renderTree = useCallback((nodes: ITreeData[]) => {

        return (
            <>
                {nodes.map((node) => node.visible &&
                    <TreeItemView
                        key={node.id}
                        node={node}
                        selected={selected}
                        getOnChange={getOnChange}
                        searchText={searchText}
                    />
                )}
            </>
        );
    }, [treeItemsData, selected]);

    const onNodeToggle = (e: SyntheticEvent<Element, Event>, nodeIds: string[]) => {
        setExpanded(nodeIds);
    }

    const handleDelete = (chipToDelete: ITreeData) => () => {
        setSelected((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
    };

    const scrollChild = (chipClicked: ITreeData) => {

        const element = document.getElementById(chipClicked.id);

        if (!element) {
            return;
        }

        const parent = parentTreeViewRef?.current!;

        const child = element!;

        const parentRect = parent.getBoundingClientRect();
        // What can you see?
        const parentViewableArea = {
            height: parent.clientHeight,
            width: parent.clientWidth
        };

        // Where is the child
        const childRect = child.getBoundingClientRect();

        const isViewable = (childRect.top >= parentRect.top) && (childRect.bottom <= parentRect.top + parentViewableArea.height);

        if (!isViewable) {
            const scrollTop = childRect.top - parentRect.top;
            parent.scrollTop += scrollTop;
        }

        bgFade(element, 10);
    };

    const handleClick = (chipClicked: ITreeData) => (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        scrollChild(chipClicked);
    };

    const clearSelected = () => {
        setSelected([]);
    };

    const reducer = (treeItem: ITreeData, searchText: string): ITreeData => {
        if (!!treeItem.children) {
            for (let i=0; i<treeItem.children.length; i++) {

                let treeItemChild = treeItem.children[i];
                let treeItemReduced: ITreeData = reducer(treeItemChild, searchText);
                if (!treeItemReduced.children) {
                    treeItemReduced.visible = ResourceTreeService.treeItemChecker(treeItemReduced, searchText);
                } else {
                    if (
                        ResourceTreeService.treeItemChecker(treeItemReduced, searchText)
                        ||
                        ResourceTreeService.treeItemVisible(treeItemReduced)
                    ) {
                        treeItemReduced.visible = true;
                        if (!!searchText && treeItemReduced.level !== ResourceTypeEnum.DOCTOR) {
                            tempExpanded = [...tempExpanded, treeItemReduced.id];
                        }
                    } else {
                        treeItemReduced.visible = false;
                    }
                }
                treeItemChild = treeItemReduced;
            }
        } else {
            treeItem.visible = ResourceTreeService.treeItemChecker(treeItem, searchText);
        }
        return treeItem;
    };

    const callSearch = useCallback((searchText: string) => {

        if (!!searchText && searchText.length < SEARCH_LIMIT) {
            return;
        }

        if (!!searchText) {
            tempExpanded = [];
        }

        const filteredTreeItemsChildren: ITreeData[] = (treeItemsData.children || [])
            .map((treeItem: ITreeData) => reducer(treeItem, searchText))
            .map((treeItem: ITreeData) => {
                treeItem.visible =
                    ResourceTreeService.treeItemChecker(treeItem, searchText)
                    ||
                    ResourceTreeService.treeItemVisible(treeItem)
                if (!!searchText && treeItem.visible) {
                    tempExpanded = [...tempExpanded, treeItem.id];
                }
                return treeItem;
            });

        setTreeItemsData({
            id: '0',
            name: 'root',
            level: ResourceTypeEnum.ROOT,
            visible: true,
            children: filteredTreeItemsChildren
        });

        if (!!searchText) {
            setExpanded(tempExpanded);
        }
        setIsSearching(false);

    }, [searchText]);

    const getLevelName = (level: ResourceTypeEnum): string => {
        let result: string = '';
        switch (level) {
            case ResourceTypeEnum.REGION:
                result = 'Регион';
                break;
            case ResourceTypeEnum.MED_ORG:
                result = 'Мед.орг';
                break;
            case ResourceTypeEnum.DOCTOR:
                result = 'Доктор';
                break;
        }

        return result;
    }

    const getChipLabel = (data: ITreeData) => {
        return <>
            <Typography component={'span'} variant={'caption'} sx={{fontWeight: 600}}>{getLevelName(data.level) + ': '}</Typography>
            <Typography component={'span'} variant={'caption'} >{data.name}</Typography>
        </>;
    }

    return (
        <Box
            height="100%"
            flexDirection="column"
            display="flex"
            paddingBottom="20px"
        >
            <CssBaseline />
            <Box p={0} marginBottom={'8px'}>
                <Grid2 container spacing={3} sx={{padding: 0}}>
                    {/*chips and clear-all button*/}
                    {selected.length > 0 ? (
                        <Grid2 xs={12}>
                            <Grid2 sx={{minWidth: 'fit-content'}} alignSelf={'start'} display={'flex'}>
                                Выбранные:
                            </Grid2>
                            <Grid2 container direction={'row'} sx={{padding: 0}} flexWrap={'nowrap'} alignItems={'center'}>
                                <Grid2 flexGrow={1} display={'flex'} alignItems={'center'} alignSelf={'center'}>
                                    <Grid2
                                        container
                                        direction={'row'}
                                    >
                                        <Grid2
                                            sx={{width: '100%', display: 'flex', justifyContent: 'start', padding: '4px 0'}}
                                            flexWrap={'wrap'}
                                            gap={1}
                                        >
                                            {selected.sort((a, b) => +a.level[0] - +b.level[0]).map((data) => {
                                                return (
                                                    <ListItem key={data.id} sx={{width: 'fit-content', padding: '0 0 0 0'}}>
                                                        <Chip
                                                            size={'small'}
                                                            label={getChipLabel(data)}
                                                            onDelete={handleDelete(data)}
                                                            onClick={handleClick(data)}
                                                            color='primary'
                                                        />
                                                    </ListItem>
                                                );
                                            })}
                                        </Grid2>
                                    </Grid2>
                                </Grid2>

                                <Grid2
                                    sx={{minWidth: 'fit-content'}}
                                    alignSelf={'start'}
                                    display={'flex'}
                                >
                                    {selected.length > 0 &&
                                        <IconButton aria-label="delete" size="small" onClick={clearSelected}>
                                            <DeleteIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                </Grid2>
                            </Grid2>
                        </Grid2>
                    ) : null }

                    {/*textfield*/}
                    <Grid2 xs={12} paddingTop={0}>
                        <Grid2
                            container
                            sx={{
                                maxWidth: '350px',
                                padding: 0,
                            }}
                        >
                            <FormControl fullWidth>
                                <TextField
                                    label={'Поиск'}
                                    variant={'standard'}
                                    inputProps={{maxLength: 100,}}
                                    sx={{
                                        '& input': {
                                            textAlign: 'left'
                                        }
                                    }}
                                    size={'small'}
                                    value={searchText || ''}
                                    onChange={(e) => {
                                        setSearchText(e.target.value);
                                    }}
                                    error={!!searchText && searchText.length < SEARCH_LIMIT}
                                    helperText={!!searchText && searchText.length < SEARCH_LIMIT ? `Введите не менее ${SEARCH_LIMIT} символов` : ''}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment
                                                position="end"
                                                style={{height: '100%'}}
                                            >
                                                {isSearching && <CircularProgress size={16}/>}
                                                <ClearIcon
                                                    style={{marginLeft: '8px', cursor: 'pointer'}}
                                                    onClick={() => {
                                                        setSearchText('');
                                                    }}
                                                    fontSize={'small'}
                                                    color={'primary'}
                                                />
                                            </InputAdornment>
                                    }}
                                />
                            </FormControl>

                        </Grid2>
                    </Grid2>
                </Grid2>
            </Box>

            <Box
                flex={1}
                flexDirection="column"
                display="flex"
                p={0}
                mb={4}
                sx={{scrollBehavior: 'smooth', overflowY: 'auto', overflowX: 'hidden', marginBottom: 0}}
                ref={parentTreeViewRef}
            >
                <Grid2 sx={{ display: "flex", flex: 1, marginRight: '8px', maxHeight: '100%' }} container spacing={0}>
                    <Grid2 sx={{ display: "flex", flexDirection: 'column', flex: 1, padding: 0 }} xs={12}>
                        {(isLoadingTreeData) &&
                            <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                sx={{ width: '100%', height: '100%', }}
                            >
                                <CircularProgress />
                            </Box>
                        }
                        {(!isLoadingTreeData) &&
                        <Box overflow="auto" id="scroll" flex={1} bgcolor="white">
                            <TreeView
                                sx={{width: '100%',}}
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpanded={expanded}
                                expanded={expanded}
                                defaultExpandIcon={<ChevronRightIcon />}
                                onNodeToggle={onNodeToggle}
                            >
                                {renderTree(treeItemsData.children || [])}
                            </TreeView>
                        </Box>
                        }
                    </Grid2>
                </Grid2>
            </Box>

        </Box>
    );
};


/**
 *  return true if passing nextProps to render
 *  would return the same result
 *  as passing prevProps to render,
 *  otherwise return false
 *  */
const isSame = (prevProps: Readonly<DataTreeViewProps>, nextProps: Readonly<DataTreeViewProps>): boolean => {
    return prevProps.isLoadingTreeData === nextProps.isLoadingTreeData;
}

export default memo(RecursiveTreeView, isSame);
