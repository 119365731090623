import React, {ChangeEvent, memo} from 'react';
import {
    Checkbox,
    FormControlLabel,
    Typography,
} from '@mui/material';
import 'moment/locale/ru';
import TreeItem from '@mui/lab/TreeItem';
import {ITreeData} from '../../models/users/ITreeData';
import Highlighter from 'react-highlight-words';
import ResourceTreeService from "../../services/ResourceTreeService";

interface TreeItemViewProps {
    node: ITreeData,
    selected: ITreeData[],
    searchText: string,
    getOnChange: (nodes: ITreeData) => (event: ChangeEvent<HTMLInputElement>) => void,
}

const TreeItemView = ({node: node, selected, getOnChange, searchText}: TreeItemViewProps) => {

        const checked = (selected.filter((selectedItem) => selectedItem.id === node.id).length > 0) || false;

        const onClickStop = (event: any) => {
            event.stopPropagation();
        }

        const onClickStopAll = (event: any) => {
            event.preventDefault();
            event.stopPropagation();
        }

    const highlightSearchText = (node: ITreeData) => {
            return <Highlighter
                highlightStyle={{backgroundColor: '#cbefaa'}}
                searchWords={(!!searchText && node.id.endsWith(searchText)) ? [node.name] : [searchText]}
                autoEscape={true}
                textToHighlight={node.name}
            />
    }

    return (
        <TreeItem
            key={node.id}
            nodeId={node.id}
            label={
                <FormControlLabel
                    control={
                        <Checkbox
                            size={'small'}
                            sx={{padding: '5px'}}
                            checked={checked}
                            onChange={getOnChange(node)}
                            onClick={onClickStop}
                        />
                    }
                    label={<Typography
                        id={node.id}
                        sx={{
                            fontWeight: !!searchText && ResourceTreeService.treeItemChecker(node, searchText) ? '500' : 'unset',
                        }}
                    >
                        {highlightSearchText(node)}
                    </Typography>}
                />
            }
            onClick={onClickStopAll}
        >
            {Array.isArray(node.children)
                ? node.children.map((child_node) => {
                        if (child_node.visible) {

                            return <TreeItemView
                                key={child_node.id}
                                node={child_node}
                                selected={selected}
                                getOnChange={getOnChange}
                                searchText={searchText}
                            />
                        }
                    }
                ) : null
            }
        </TreeItem>
    );
};

export default memo(TreeItemView);

