import React, {Fragment} from 'react';
import './HomePage.css';
import medicbkLogo from '../../assets/medicbk.png';


function HomePage() {
    return (
        <Fragment>
            <div className="App">
                <header className="App-header">
                    <img src={medicbkLogo} className="App-logo" alt="Medic-BK"/>
                </header>
            </div>
        </Fragment>
    );
}

export default HomePage;
