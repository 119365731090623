import {IEnvConfig} from "./app/models/IEnvConfig";
import React from "react";

const getEnvConfig = async () => {

    return await fetch('/config.json', {
            headers: {
                'Accept': 'application/json'
            }
        }
    )
        .then((response) => response.json())
        .catch((e) => {
            console.log("expection", e);
            return {
                API_GRAPHQL_HOST: "",
                API_NR_DOCTOR_ENDPOINT: "",
                API_NR_OID_ENDPOINT: "",
                API_NR_REGION_ENDPOINT: "",
                API_NR_URL: "",
                API_PROTOCOL: "",
                API_REST_HOST: "",
                API_REST_LOGIN: "",
                GRAPHQL_ENDPOINT: "",
                GRAPHQL_PORT: 0,
                PORT: 0,
                REST_PORT: 0
            }
        });
}

export const ENV_CONFIG: IEnvConfig = await getEnvConfig();

export const checkEnvConfig = (config: IEnvConfig): boolean => {
    return !!config.API_REST_LOGIN
        && !!config.API_GRAPHQL_HOST
        && !!config.API_NR_DOCTOR_ENDPOINT
        && !!config.API_NR_OID_ENDPOINT
        && !!config.API_NR_REGION_ENDPOINT
        && !!config.API_NR_URL
        && !!config.API_PROTOCOL
        && !!config.API_REST_HOST
        && !!config.GRAPHQL_ENDPOINT
        && !!config.GRAPHQL_PORT
        && !!config.PORT
        && !!config.REST_PORT;
}
