import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TextField} from "@mui/material";
import React from "react";
import dayjs from "dayjs";


interface CustomDateInputProps {
    pickedValue: any,
    handleDatePick: any,
    minDate?: dayjs.Dayjs,
    maxDate?:dayjs.Dayjs
}

export const CustomDateInput = ({pickedValue, handleDatePick, minDate, maxDate}: CustomDateInputProps) => {

    return <DatePicker
        minDate={minDate}
        maxDate={maxDate}
        label={"Выберите день"}
        value={pickedValue}
        onChange={(newValue: dayjs.Dayjs | null) => handleDatePick(newValue)}
        renderInput={props => <TextField {...props}/>}
        inputFormat="DD.MM.YYYY"
        dayOfWeekFormatter={(day: string) => day.charAt(0).toUpperCase() + day.charAt(1)}
        showDaysOutsideCurrentMonth={true}
    ></DatePicker>

}