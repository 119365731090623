import React, {Fragment, MutableRefObject, useRef, useState} from 'react';
import {TextField} from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import {useLocation, useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {observer} from 'mobx-react';
import AuthStore from '../stores/auth';
import {useSignInLazyQuery} from "../../graphql/generated/graphql";
import {toast} from 'react-toastify';
import {logger} from '../services/LoggerService';
import {ENV_CONFIG} from "../../getEnvConfig";


const SignInPage = observer(({store}: {store: AuthStore}) => {

    const usernameRef: MutableRefObject<any> = useRef();
    const passwordRef: MutableRefObject<any> = useRef();
    const navigate = useNavigate();
    const location = useLocation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [signIn, { loading: signInLoading, error: signInError }] = useSignInLazyQuery();

    const callSignIn = () => {
        sessionStorage.removeItem('x_auth_token');
        sessionStorage.removeItem('username');
        store.token = undefined;
        store.username = undefined;

        const body = JSON.stringify({
            username: username,
            password: password
        });
        fetch(ENV_CONFIG.API_PROTOCOL + "://" + ENV_CONFIG.API_REST_HOST + ":" + ENV_CONFIG.REST_PORT + "/" + ENV_CONFIG.API_REST_LOGIN, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: body
        })
            .then((response) => response.json())
            .then((response) => {
                if (!!response.error) {
                    toast(response.error, {type: 'error'});
                } else {
                    if (!!response.jwt && !!response.username) {
                        sessionStorage.setItem('x_auth_token', response.jwt);
                        sessionStorage.setItem('username', response.username);
                        store.token = response.jwt || undefined;
                        store.username = response.username || undefined;

                        toast(`Добро пожаловать, ${response.username}!`, {type: 'success'});

                        if (location.state && location.state.from) {
                            navigate(location.state.from);
                        } else {
                            navigate('/users');
                        }
                    }
                }
            })
            .catch((err) => {
                logger.error('Login error:', err);
                toast(`Ошибка логина`, {type: 'error'});
            });

        // store.signInWithEmail({email: email, password: password});
    }

    const onKeyEnter = () => {
        if (!!username && !!password) {
            callSignIn();
        } else {
            if (!username) {
                usernameRef.current?.focus();
            } else if (!password) {
                passwordRef.current?.focus();
            }
        }
    }

    return (
        <Fragment>
                <Container>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Вход
                        </Typography>
                        <Box>
                            <TextField
                                inputRef={usernameRef}
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Логин"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value)
                                }}
                                onKeyDown={(event) => event.key === 'Enter' && onKeyEnter()}
                            />
                            <TextField
                                inputRef={passwordRef}
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Пароль"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                }}
                                onKeyDown={(event) => event.key === 'Enter' && onKeyEnter()}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                onClick={callSignIn}
                            >
                                Вход
                            </Button>
                        </Box>
                    </Box>
                </Container>

        </Fragment>
    );
});

export default SignInPage;
