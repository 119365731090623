import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {CustomDateRangeInput} from "../../common/Inputs/CustomDateRangeInput";
import Grid2 from "@mui/material/Unstable_Grid2";
import {CustomCheckbox} from "../../common/Inputs/CustomCheckbox";
import {List, ListItem, ListItemButton} from "@mui/material";
import {DocumentStatusColorEnum, DocumentStatusEnum} from "../../../models/enums/DocumentStatusEnum";
import {
    useGetDocumentStatusCountLazyQuery,
    useGetDocumentsByParamsLazyQuery,
} from "../../../../graphql/generated/graphql";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {IStatusRatio} from "../../../models/stats/IStatusRatio";
import {StyledDataGrid} from "../../common/StyledDataGrid";
import {X_DATA_GRID_LOCALETEXT} from "../../../config/xdatagrid";
import {GridColDef} from "@mui/x-data-grid";
import {IDocument} from "../../../models/stats/IDocument";
import {Typography} from "@mui/material";

export const DocumentStaticStats = (): ReactJSXElement => {

    const [getStatusRatio, {loading: statusRatioLoading, error: statusRatioError}] = useGetDocumentStatusCountLazyQuery();
    const [documents, setDocuments] = useState<IDocument[]>([]);

    const [getDocuments, {loading: getDocumentsLoading, error: getDocumentsError}] = useGetDocumentsByParamsLazyQuery();
    const [statusData, setStatusData] = useState<IStatusRatio>({ready: 0, error: 0});

    const [page, setPage] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);

    const [dateRange, setDateRange] = useState({
        start: dayjs().subtract(7, 'day'),
        end: dayjs()
    });

    const [checkedStatus, setCheckedStatus] = useState<string[]>([DocumentStatusEnum.ERROR, DocumentStatusEnum.COMPLETE]);

    useEffect(() => {
        const variables = {
            from: dateRange.start.valueOf().toString(),
            to: dateRange.end.valueOf().toString(),
            status: checkedStatus
        }

        getStatusRatio({variables: variables})
            .then((response) => {

                if (response?.data?.getDocumentStatusCount?.length) {
                    let ready: number, error: number = 0;
                    for (let item of response?.data?.getDocumentStatusCount) {
                        if (item?.status === DocumentStatusEnum.COMPLETE) ready = item.count || 0;
                        if (item?.status === DocumentStatusEnum.ERROR) error = item.count || 0;
                    }
                    setStatusData((prevState) => ({ready, error}));
                } else {
                    setStatusData({ready: 0, error: 0});
                }


            })
            .catch((error) => {
                console.log(error);
                setStatusData({ready: 0, error: 0});
            })

    }, [dateRange, checkedStatus])

    useEffect(() => {

        const variables2 = {
            from: dateRange.start.valueOf().toString(),
            to: dateRange.end.valueOf().toString(),
            status: checkedStatus,
            paging: {
                page: page,
                size: pageSize
            }
        }

        getDocuments({variables: variables2})
            .then((response) => {
                console.log(23, response)
                if (response?.data?.getDocumentsByParams?.documents?.length) {
                    const data = response.data.getDocumentsByParams.documents
                        .filter(document => document !== null)
                        .map((document) => {
                            let newDocument: IDocument = {
                                id: document?.id || 0,
                                error_descr: document?.error_descr || "",
                                message_uuid: document?.message_uuid || "",
                                patient_uuid: document?.patient_uuid || "",
                                created_at: document?.created_at ? dayjs(parseInt(document?.created_at)).format("YYYY-MM-DD HH:mm:ss") : "invalid date",
                                status: document?.status || ""
                            }
                            return newDocument;
                        });

                    setDocuments((prevState) => data);
                    setRowCount((prevState) => response.data?.getDocumentsByParams?.paging?.totalElements || 0);
                } else {
                    setDocuments((prevState) => []);
                }
            })
            .catch((error) => {
                console.log(error);
                setDocuments((prevState) => []);
            })

    }, [dateRange, checkedStatus, page, pageSize])

    const handleDateRangeChange = (date: dayjs.Dayjs, key: "end" | "start") => {
        const newValue: { start?: dayjs.Dayjs, end?: dayjs.Dayjs } = {};
        newValue[key] = date;
        setDateRange(range => ({...range, ...newValue}));
    }

    const handleStatusChange = (status: string) => {
        const currentIndex = checkedStatus.indexOf(status);
        const newCheckedStatus = [...checkedStatus];

        if (currentIndex === -1) {
            newCheckedStatus.push(status);
        } else {
            newCheckedStatus.splice(currentIndex, 1);
        }

        setCheckedStatus(newCheckedStatus);
    }

    const data = React.useMemo(() => [
        {
            "name": "Статус",
            "COMPLETE": statusData.ready,
            "ERROR": statusData.error
        }
    ], [statusData]);

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 1,
            minWidth: 80,
            headerClassName: 'actionsHeader',
            sortable: false,
            disableColumnMenu: true,
            disableReorder: true,
        },
        {field: 'message_uuid', headerName: 'message_uuid', sortable: false, flex: 1, minWidth: 300},
        {field: 'patient_uuid', headerName: 'UUID пациента', sortable: false, flex: 1, minWidth: 300},
        {field: 'status', headerName: 'Статус', sortable: false, flex: 1, minWidth: 100},
        {field: 'error_descr', headerName: 'Сообщение', sortable: false, flex: 25},
        {field: 'created_at', headerName: 'Время создания', sortable: false, flex: 1, minWidth: 160},
    ];

    return <>
        <Grid2 sx={{display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 4}}>

            <Grid2 sx={{display: "flex", flexDirection: "row", alignItems: "flex-start", gap: 3}}>
                <CustomDateRangeInput dateRange={dateRange} setDateRange={handleDateRangeChange} error={getDocumentsError}></CustomDateRangeInput>
                <Grid2 sx={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                    <Grid2 sx={{mb: 1}}>Статусы документов</Grid2>
                    <List disablePadding>
                        {[DocumentStatusEnum.COMPLETE, DocumentStatusEnum.ERROR].map((status: string) =>
                            <ListItem disablePadding key={`document-status-checkbox-${status}-key`}>
                                <ListItemButton dense onClick={() => handleStatusChange(status)}>
                                    <CustomCheckbox
                                        isChecked={checkedStatus.indexOf(status) !== -1}
                                        label={status}
                                        id={`document-status-checkbox-${status}`}
                                    ></CustomCheckbox>
                                </ListItemButton>
                            </ListItem>
                        )}
                    </List>
                </Grid2>
            </Grid2>

            <Grid2 sx={{maxHeight: '400px', mL: 3, minWidth: "600px", width: "100%"}}>
                <ResponsiveContainer width="100%" height={160}>
                    <BarChart data={data} layout="vertical">
                        <CartesianGrid strokeDasharray="3 3"/>
                        <XAxis type="number"/>
                        <YAxis dataKey="name" type="category"/>
                        <Tooltip/>
                        <Legend/>
                        <Bar dataKey="COMPLETE" fill={DocumentStatusColorEnum.COMPLETE}/>
                        <Bar dataKey="ERROR" fill={DocumentStatusColorEnum.ERROR}/>
                    </BarChart>
                </ResponsiveContainer>
            </Grid2>
        </Grid2>

        <Typography component="h3" variant="h6">Входящие документы</Typography>

        <StyledDataGrid
            autoHeight
            loading={getDocumentsLoading}
            localeText={X_DATA_GRID_LOCALETEXT}
            paginationMode="server"
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 50, 100]}
            rows={documents}
            rowCount={rowCount}
            columns={columns}
            page={page}
            onPageChange={(page) => setPage(page)}
            onPageSizeChange={(pageSize, details) => {
                setPageSize(pageSize);
            }}
            hideFooterSelectedRowCount={true}
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
        />

    </>
}