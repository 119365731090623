import {ITreeData} from "../models/users/ITreeData";
import {ILabel} from "../models/users/ILabel";
import {ITreeResources} from "../models/users/ITreeResources";
import UsersStore from "../stores/users";
import {ResourceTypeEnum} from "../models/enums/ResourceTypeEnum";
import {IUserRegion} from "../models/users/IUserRegion";
import {IUserMedOrg} from "../models/users/IUserMedOrg";
import {IUserDoctor} from "../models/users/IUserDoctor";
import NamesResolveService from "./NamesResolveService";
import {IRegion} from "../models/users/IRegion";
import {IMedOrg} from "../models/users/IMedOrg";
import {IDoctor} from "../models/users/IDoctor";


export default class ResourceTreeService {
    static readonly LEVELS_SEPARATOR: string = '@';
    static readonly namesResolveService = NamesResolveService

    private static resourceKeyFromITreeData(treeNode: ITreeData): string  {
        return treeNode.id.split(this.LEVELS_SEPARATOR).pop() || treeNode.id;
    }

    public static getLabelById(id: string, labels: ILabel[]): string {
        const foundLabel: ILabel | undefined = labels.find((label) => label.code === id);
        return (foundLabel || {}).value || id;
    }

    private static concatLevels(levels: string[]) {
        return levels.join(this.LEVELS_SEPARATOR);
    }

    public static getRootNode() {
        return {
            id: '0',
            name: 'Корень',
            level: ResourceTypeEnum.ROOT,
            visible: true,
            children: [],
        };
    }

    public static async treeResources2ITreeData(treeResources: ITreeResources, store: UsersStore) {

        const treeDataRoot: ITreeData = this.getRootNode();

        const regionLabels = await this.namesResolveService.getRegionLabels(treeResources?.region.map(item => item.name));
        const oidsLabels = await this.namesResolveService.getMedOrgLabels(
            treeResources.region
                .map((region: IRegion) => region.medOrg || [])
                .reduce((accumulator: string[], currentValue: IMedOrg[]) =>
                    accumulator.concat(currentValue.map(medOrg => medOrg.name)),
                    []
                )
        );
        const doctorsLabels = await this.namesResolveService.getDoctorLabels(
            treeResources.region
                .map((region: IRegion) => region.medOrg || [])
                .reduce(
                    (accumulator: IMedOrg[], currentValue: IMedOrg[]) => accumulator.concat(currentValue),
                    []
                )
                .reduce(
                    (accumulator: IDoctor[], currentValue: IMedOrg) => accumulator.concat(currentValue.doctor || []),
                    []
                )
                .map(doctor => doctor.name)
        );

        treeResources.region.forEach((region) => {
            const treeDataRegion: ITreeData = {} as ITreeData;
            treeDataRegion.id = this.concatLevels(['root', region.name]);
            treeDataRegion.name = this.getLabelById(region.name, regionLabels);
            treeDataRegion.level = ResourceTypeEnum.REGION;
            treeDataRegion.visible = true;
            treeDataRegion.children = [];

            treeDataRoot.children?.push(treeDataRegion);

            (region.medOrg || []).forEach((medOrg) => {
                const treeDataMedOrg: ITreeData = {} as ITreeData;
                treeDataMedOrg.id = this.concatLevels(['root', region.name, medOrg.name]);
                treeDataMedOrg.name = this.getLabelById(medOrg.name, oidsLabels);
                treeDataMedOrg.level = ResourceTypeEnum.MED_ORG;
                treeDataMedOrg.visible = true;
                treeDataMedOrg.children = [];

                treeDataRegion.children?.push(treeDataMedOrg);

                (medOrg.doctor || []).forEach((doctor) => {
                    const treeDataDoctor: ITreeData = {} as ITreeData;
                    treeDataDoctor.id = this.concatLevels(['root', region.name, medOrg.name, doctor.name]);
                    treeDataDoctor.name = this.getLabelById(doctor.name, doctorsLabels);
                    treeDataDoctor.level = ResourceTypeEnum.DOCTOR;
                    treeDataDoctor.visible = true;

                    treeDataMedOrg.children?.push(treeDataDoctor);
                });
            });
        });
        return treeDataRoot;

    };

    public static prepareTreeNodeIds (tree: ITreeData, selected: ITreeData[]): ITreeData[] {

        for (let node of selected) {

            if (node.level === ResourceTypeEnum.REGION) {
                for (let regionNode of (tree?.children || [])) {

                    if (this.resourceKeyFromITreeData(regionNode) === node.id) {
                        node.id = regionNode.id;
                        node.name = regionNode.name;
                        break;
                    }
                }
            } else if (node.level === ResourceTypeEnum.MED_ORG) {

                outermost:
                    for (let regionNode of (tree?.children || [])) {
                        for (let medOrgNode of (regionNode?.children || [])) {

                            if (this.resourceKeyFromITreeData(medOrgNode) === node.id) {
                                node.id = medOrgNode.id;
                                node.name = medOrgNode.name;
                                break outermost;
                            }
                        }
                    }
            } else if (node.level === ResourceTypeEnum.DOCTOR) {

                outermost:
                    for (let regionNode of (tree?.children || [])) {
                        for (let medOrgNode of (regionNode?.children || [])) {
                            for (let doctorNode of (medOrgNode?.children || [])) {

                                if (this.resourceKeyFromITreeData(doctorNode) === node.id) {
                                    node.id = doctorNode.id;
                                    node.name = doctorNode.name;
                                    break outermost;
                                }
                            }
                        }
                    }
            }
        }

        return selected;
    }

    public static getParentNodes = (node: ITreeData, treeData: ITreeData): ITreeData[] => {
        const parentNodes: ITreeData[] = [];

        if (node.level === ResourceTypeEnum.MED_ORG && !!treeData.children) {
            for (let regionNode of (treeData.children || [])) {
                if (regionNode.id === node.id
                    .split(this.LEVELS_SEPARATOR)
                    .slice(0, -1)
                    .join(this.LEVELS_SEPARATOR)) {
                    parentNodes.push(regionNode);
                }
            }
        } else if (node.level === ResourceTypeEnum.DOCTOR && !!treeData.children) {
            for (let regionNode of (treeData.children || [])) {
                if (regionNode.id === node.id
                    .split(this.LEVELS_SEPARATOR)
                    .slice(0, -2)
                    .join(this.LEVELS_SEPARATOR)) {
                    parentNodes.push(regionNode);

                    for (let medOrgNode of (regionNode.children || [])) {
                        if (medOrgNode.id === node.id
                            .split(this.LEVELS_SEPARATOR)
                            .slice(0, -1)
                            .join(this.LEVELS_SEPARATOR)) {
                            parentNodes.push(medOrgNode);
                        }
                    }
                }
            }
        }

        return parentNodes;
    }

    public static getUserRegionDTO(selectedResources: ITreeData[], userId: number): IUserRegion[] {
        return selectedResources
            .filter((userSelectedResource) => userSelectedResource.level === ResourceTypeEnum.REGION)
            .map((userSelectedResource: ITreeData) => {
                return {
                    userId: userId,
                    regionId: this.resourceKeyFromITreeData(userSelectedResource)
                } as IUserRegion;
            });
    }

    public static getUserMedOrgDTO(selectedResources: ITreeData[], userId: number): IUserMedOrg[] {
        return selectedResources
            .filter((userSelectedResource) => userSelectedResource.level === ResourceTypeEnum.MED_ORG)
            .map((userSelectedResource) => {
                return {
                    userId: userId,
                    medOrgId: this.resourceKeyFromITreeData(userSelectedResource)
                } as IUserMedOrg;
            });
    }

    public static getUserDoctorDTO(selectedResources: ITreeData[], userId: number): IUserDoctor[] {
        return selectedResources
            .filter((userSelectedResource) => userSelectedResource.level === ResourceTypeEnum.DOCTOR)
            .map((userSelectedResource) => {
                return {
                    userId: userId,
                    doctorId: this.resourceKeyFromITreeData(userSelectedResource)
                } as IUserDoctor;
            });
    }

    public static treeItemChecker = (treeItem: ITreeData | undefined, searchText: string): boolean => {
        return (!searchText
            || (
                !!treeItem
                && treeItem.name.toLowerCase().includes(searchText.toLowerCase())
            )
            || (
                !!treeItem
                && treeItem.id.endsWith(ResourceTreeService.LEVELS_SEPARATOR + searchText)
            )
        );
    };

    public static treeItemVisible = (treeItem: ITreeData): boolean => {
        return (treeItem.children || []).filter((value) => value.visible).length > 0;
    };
}