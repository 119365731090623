import {CustomDateInput} from "./CustomDateInput";
import Grid2 from "@mui/material/Unstable_Grid2";
import dayjs from "dayjs";
import {IDateRange} from "../../../models/stats/IDateRange";
import {Typography, useTheme} from "@mui/material";
import {ApolloError} from "@apollo/client";
import {useState} from "react";
import {WarningAmber} from "@mui/icons-material";


interface CustomDateRangeInputProps {
    dateRange: IDateRange,
    setDateRange: (date: dayjs.Dayjs, key: "end" | "start") => void,
    error?: ApolloError
}

export const CustomDateRangeInput = ({dateRange, setDateRange, error}: CustomDateRangeInputProps) => {

    const [startDate, setStartDate] = useState<dayjs.Dayjs>();
    const [endDate, setEndDate] = useState<dayjs.Dayjs>();

    const theme = useTheme();

    const handleDateSet = (newDate: dayjs.Dayjs | null, key: "end" | "start") => {
        if (newDate) {

            if (key === "end") {
                setEndDate(newDate);
                newDate = newDate.set('hour', 23).set('minute', 59).set('second', 59)
            }
            if (key === "start") {
                setStartDate(newDate);
            }
            setDateRange(newDate, key);
        }
    }

    return <Grid2 sx={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
        <Grid2 sx={{mb: 2}}>Задайте период</Grid2>
        <Grid2 sx={{display: "flex", flexDirection: "row", alignItems: "center", gap: 3}}>
            <p>с</p>
            <CustomDateInput
                pickedValue={dateRange.start}
                handleDatePick={(date: dayjs.Dayjs | null) => handleDateSet(date, "start")}
                maxDate={endDate}
            ></CustomDateInput>
            <p>по</p>
            <CustomDateInput
                pickedValue={dateRange.end}
                handleDatePick={(date: dayjs.Dayjs | null) => handleDateSet(date, "end")}
                minDate={startDate}
            ></CustomDateInput>
        </Grid2>
        <Grid2>
            <Typography sx={{color: theme.palette.error.dark || "red", pl: 3}} variant="caption">
                {error?.message ? <span ><WarningAmber style={{verticalAlign: "bottom", paddingRight: "4px"}} fontSize="small"/>{error.message}</span> : null}
            </Typography>
        </Grid2>
    </Grid2>
}