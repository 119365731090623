export const copyWithoutCircularReferences = (references: any, object: any) => {
    let cleanObject: any = {};
    Object.keys(object).forEach(function(key) {
        let value = object[key];
        if (value && typeof value === 'object') {
            if (references.indexOf(value) < 0) {
                references.push(value);
                cleanObject[key] = copyWithoutCircularReferences(references, value);
                references.pop();
            } else {
                cleanObject[key] = '###_Circular_###';
            }
        } else if (typeof value !== 'function') {
            cleanObject[key] = value;
        }
    });
    return cleanObject;
};

export const cleanStringify = (object: any) => {
    if (object && typeof object === 'object') {
        object = copyWithoutCircularReferences([object], object);
    }
    return JSON.stringify(object);
};

export const copyData = (data: any): any => {
    return JSON.parse(JSON.stringify(cleanStringify(data)));
};

export const bgFade = (element: HTMLElement, bgOpacity: number) => {
    if (bgOpacity >= 0) {
        element.style.backgroundColor = "rgba(203, 239, 170, " + bgOpacity + ")";
        setTimeout(() => bgFade(element, bgOpacity - 0.2), 30);
    } else {
        element.style.removeProperty('background-color');
    }
}
