import {action, computed, configure, makeObservable, observable} from 'mobx';
import {RootStore} from './root';

configure({
    enforceActions: "never",
});

export default class AuthStore {
    rootStore: RootStore;
    username: string | undefined = undefined;
    accessToken: string | undefined = undefined;
    refreshToken: string | undefined = undefined;
    token: string | undefined = undefined;
    user: any = undefined;
    disabledOnLogin: boolean = false;
    disabledOnSignUpWithEmail: boolean = false;
    disabledOnLoginWithProvider: boolean = false;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        const token = sessionStorage.getItem('x_auth_token');
        if (!!token) {
            this.user = token;
        }

        makeObservable(this, {
            setAccessToken: action,
            setRefreshToken: action,
            clearUser: action,
            accessToken: observable,
            refreshToken: observable,
            user: observable,
            username: observable,
            token: observable,
            disabledOnLogin: observable,
            disabledOnSignUpWithEmail: observable,
            disabledOnLoginWithProvider: observable,
            isAuth: computed,
        });
    }

    get isAuth(): boolean {
        return (
            !!this.token
        );
    }

    setAccessToken(accessToken: string): void {
        this.accessToken = accessToken;
    }

    setRefreshToken(refreshToken: string): void {
        this.refreshToken = refreshToken;
    }

    signOut = () => {
        this.clearUser();
    }

    clearUser = () => {
        this.user = undefined;
        this.username = undefined;
    }
}
