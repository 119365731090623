import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Grid2 from "@mui/material/Unstable_Grid2";
import React, {useEffect, useState} from "react";
import {useGetReportStatusCountLazyQuery} from "../../../../graphql/generated/graphql";
import {IStatusRatio} from "../../../models/stats/IStatusRatio";
import {CustomDateRangeInput} from "../../common/Inputs/CustomDateRangeInput";
import dayjs from "dayjs";
import {ReportStatusColorEnum, ReportStatusEnum} from "../../../models/enums/ReportStatusEnum";
import {Typography} from "@mui/material";

export const ReportDynamicStats = () => {

    const [getStatusRatio, { loading: statusRatioLoading, error: statusRatioError }] = useGetReportStatusCountLazyQuery();
    const [statusData, setStatusData] = useState<IStatusRatio[]>([{ready: 0, error: 0}]);
    
    const [dateRange, setDateRange] = useState({
        start: dayjs().subtract(7, 'day'),
        end: dayjs()
    });

    const handleDateRangeChange = (date: dayjs.Dayjs, key: "end" | "start") => {
        const newValue: {start?: dayjs.Dayjs, end?: dayjs.Dayjs} = {};
        newValue[key] = date;
        setDateRange(range => ({...range, ...newValue}) );
    }

    const data = React.useMemo(() => statusData
        .map(item => {
            return {
            "name": item?.date || "no_label",
            "READY": item.ready,
            "ERROR": item.error
        }})
            .sort((a, b) => (a?.name || "").localeCompare(b?.name))
        , [statusData]);

    useEffect(() => {

        setStatusData((prevState) => []);

        const range: number = dateRange.end.diff(dateRange.start, "day");
        console.log(range)
        for (let i = 1; i <= range; i++) {
            let date = dateRange.start.add(i, "day").format("YYYY-MM-DD");
            let variables = {
                from: dateRange.start.add(i - 1, "day").valueOf().toString(),
                to: dateRange.start.add(i, "day").valueOf().toString(),
                status: [ReportStatusEnum.ERROR, ReportStatusEnum.READY]
            }

            getStatusRatio({variables: variables})
                .then((response) => {

                    if (response?.data?.getReportStatusCount?.length) {

                        let ready: number = 0, error: number = 0;
                        for (let item of response?.data?.getReportStatusCount) {
                            if (item?.status === ReportStatusEnum.READY) ready = item.count || 0;
                            if (item?.status === ReportStatusEnum.ERROR) error = item.count || 0;
                        }

                        console.log(i, {ready, error, date})

                        setStatusData((prevState) => ([... prevState, {ready, error, date}]));
                    } else {
                        setStatusData((prevState) => ([... prevState, {ready: 0, error: 0, date: date}]));
                    }

                })
                .catch((error) => {
                    console.log(error);
                    setStatusData((prevState) => ([... prevState, {ready: 0, error: 0, date: date}]));
                })
        }


    }, [dateRange])

    return <Grid2 sx={{display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%", gap: 4}}>
        <CustomDateRangeInput dateRange={dateRange} setDateRange={handleDateRangeChange}></CustomDateRangeInput>
        <Typography component="h3" variant="h6">Динамика генерации отчетов</Typography>
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="READY" fill={ReportStatusColorEnum.READY} />
                <Bar dataKey="ERROR" fill={ReportStatusColorEnum.ERROR} />
            </BarChart>
        </ResponsiveContainer>
    </Grid2>
}