import AuthStore from './auth';
import {NavigateFunction} from 'react-router-dom';
import UsersStore from './users';

export class RootStore {
    navigate: NavigateFunction | undefined;
    authStore;
    usersStore;

    constructor() {
        this.authStore = new AuthStore(this);
        this.usersStore = new UsersStore();
    }
}

export const rootStore = new RootStore();
