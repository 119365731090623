import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next} from "react-i18next";
import enLocales from "./locales/en/translation.json"
import ruLocales from "./locales/ru/translation.json"

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    fallbackLng: 'ru',
    debug: true,
    detection: {
        order: ["querystring", "cookie"],
        caches: ["cookie"]
    },
    interpolation: {
        escapeValue: false
    },
    resources: {
        en: {
            translation: enLocales
        },
        ru: {
            translation: ruLocales
        }
    }
});

export default i18n;