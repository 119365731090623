import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import Button from '@mui/material/Button';
import {observer} from 'mobx-react';
import UsersStore from '../../stores/users';
import {toJS} from 'mobx';
import IconButton from '@mui/material/IconButton';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    DialogActions,
    DialogContent,
    FormControl,
    InputAdornment,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    TextField,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Grid2 from '@mui/material/Unstable_Grid2';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {LocalizationProvider} from '@mui/x-date-pickers';
import 'moment/locale/ru';
import {
    useDeleteUserMutation,
    useGetListWorkspaceLazyQuery,
    useGetTreeResourcesLazyQuery,
    useGetUserDoctorsLazyQuery,
    useGetUserMedOrgsLazyQuery,
    useGetUserRegionsLazyQuery,
    UserDto,
    UserInput,
    useSaveUserDoctorsMutation,
    useSaveUserMedOrgsMutation,
    useSaveUserMutation,
    useSaveUserRegionsMutation,
    useSaveUserWorkspacesMutation
} from '../../../graphql/generated/graphql';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {toast} from 'react-toastify';
import clsx from 'clsx';
import {IWorkspace} from '../../models/IWorkspace';
import {ITreeData} from '../../models/users/ITreeData';
import RecursiveTreeView from './RecursiveTreeView';
import {StyledDialog} from '../common/StyledDialog';
import {getWorkspacesDTO} from '../../utils/converters';
import ResourceTreeService from "../../services/ResourceTreeService";
import {ITreeResources} from '../../models/users/ITreeResources';
import {IUserRegion} from '../../models/users/IUserRegion';
import {IUserMedOrg} from '../../models/users/IUserMedOrg';
import {IUserDoctor} from '../../models/users/IUserDoctor';
import {IUserWorkspace} from '../../models/users/IUserWorkspace';
import {ResourceTypeEnum} from '../../models/enums/ResourceTypeEnum';
import {logger} from '../../services/LoggerService';
import {IUser} from "../../models/users/IUser";
import {CustomCheckbox} from "../common/Inputs/CustomCheckbox";
import WorkspaceNamingService from "../../services/WorkspaceNamingService";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface UserDialogProps {
    store: UsersStore,
    reloadUsersCallback: any,
}

const UserDialog = observer(({store, reloadUsersCallback}: UserDialogProps) => {
    const [saveUser, { loading: saveUserLoading, error: saveUserError }] = useSaveUserMutation();
    const [saveUserWorkspaces, { loading: saveUserWorkspacesLoading, error: saveUserWorkspacesError }] = useSaveUserWorkspacesMutation();
    const [saveUserRegions, { loading: saveUserRegionsLoading, error: saveUserRegionsError }] = useSaveUserRegionsMutation();
    const [saveUserMedOrgs, { loading: saveUserMedOrgsLoading, error: saveUserMedOrgsError }] = useSaveUserMedOrgsMutation();
    const [saveUserDoctors, { loading: saveUserDoctorsLoading, error: saveUserDoctorsError }] = useSaveUserDoctorsMutation();
    const [deleteUser, { loading: deleteUserLoading, error: deleteUserError }] = useDeleteUserMutation();
    const [getListWorkspace, { loading: workspaceLoading, error: workspaceError }] = useGetListWorkspaceLazyQuery();
    const [getTreeResources, { loading: treeResourcesLoading, error: treeResourcesError }] = useGetTreeResourcesLazyQuery();

    const [showPassword, setShowPassword] = useState(false);
    const [checkedWorkspaces, setCheckedWorkspaces] = useState<number[]>([]);
    const [datatableWorkspaceData, setDatatableWorkspaceData] = useState<IWorkspace[]>([]);
    const [userSelectedResourcesData, setUserSelectedResourcesData] = useState<ITreeData[]>([]);

    const defaultTreeDataRoot: ITreeData = ResourceTreeService.getRootNode();

    const [treeDataState, setTreeDataState] = useState<ITreeData>(defaultTreeDataRoot);
    const treeDataStateMemo = useMemo(() => (treeDataState), [treeDataState])

    const [isLoadingTreeData, setIsLoadingTreeData] = useState<boolean>(false);

    const [selectedTreeItems, setSelectedTreeItems] = useState<ITreeData[]>([]);
    const selectedTreeItemsMemo = useMemo(() => (selectedTreeItems), [selectedTreeItems])

    const showOnLoadResourcesError = (method: string, reason: string) => {
        logger.debug(`+++ ${method}() +++ reason: ${reason}`);
        toast(`Ошибка загрузки ресурсов пользователя`, {type: 'error'});
    };

    const loadWorkspaces = () => {
        getListWorkspace()
            .then((result) => {
                setDatatableWorkspaceData(result.data?.getListWorkspace as IWorkspace[] || []);
            })
            .catch((reason) => {
                showOnLoadResourcesError('getListWorkspace', reason);
            });
    }

    const parseSelectedResources = (user: IUser) => {
        const userRegions: IUserRegion[] = user.region || [];
        const userMedOrgs: IUserMedOrg[] = user.medOrg || [];
        const userDoctors: IUserDoctor[] = user.doctor || [];

        const selectedUserRegions: ITreeData[] = userRegions.map((userRegion) => {
            return {
                id: userRegion.regionId,
                name: userRegion.slug,
                level: ResourceTypeEnum.REGION,
                visible: true,
            } as ITreeData;
        });
        const selectedUserMedOrgs: ITreeData[] = userMedOrgs.map((userMedOrg) => {
            return {
                id: userMedOrg.medOrgId,
                name: ResourceTreeService.getLabelById(userMedOrg.medOrgId, store.medOrgLabels),
                level: ResourceTypeEnum.MED_ORG,
                visible: true,
            } as ITreeData;
        });
        const selectedUserDoctors: ITreeData[] = userDoctors.map((userDoctor) => {
            return {
                id: userDoctor.doctorId,
                name: ResourceTreeService.getLabelById(userDoctor.doctorId, store.doctorLabels),
                level: ResourceTypeEnum.DOCTOR,
                visible: true,
            } as ITreeData;
        });

        setSelectedTreeItems([...selectedUserRegions, ...selectedUserMedOrgs, ...selectedUserDoctors]);
        setUserSelectedResourcesData([...selectedUserRegions, ...selectedUserMedOrgs, ...selectedUserDoctors]);
    }

    const loadTreeResources = () => {

        setIsLoadingTreeData(true);
        getTreeResources()
            .then((result) => {
                ResourceTreeService
                    .treeResources2ITreeData(result.data?.getTreeResources as ITreeResources, store)
                    .then(resultTreeData => {
                        setTreeDataState(resultTreeData);
                        setIsLoadingTreeData(false);
                    })
                    .catch((e) => {
                        showOnLoadResourcesError('getTreeResources', 'promise error');
                        setIsLoadingTreeData(false);
                    });
            })
            .catch((reason) => {
                showOnLoadResourcesError('getTreeResources', reason);
                setIsLoadingTreeData(false);
            });
    }

    useEffect(() => {
        if (store.openUserDialog) {
            loadWorkspaces();
            loadTreeResources();
        }

        if (!!store.user) {

            store.tempUser = toJS(store.user);

            if (!!store.user.id) {
                setCheckedWorkspaces(store.tempUser.workspace.map((item: IWorkspace) => item.id));
                parseSelectedResources(store.tempUser);
            }

        } else {
            store.tempUser = undefined;
        }
    }, [store.user, store.openUserDialog]);

    const onClose = (event?: any, reason?: string) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
        }
        store.setOpenUserDialog(false);
    }

    const showOnDeleteError = (reason?: string) => {
        toast(`Ошибка удаления пользователя ${store.tempUser?.username}${!!reason ? ('\n' + reason) : ''}`, {type: 'error'});
    };

    const onDelete = () => {
        deleteUser({variables: {userId: store.tempUser?.id!}})
            .then((deleteUserResult) => {
                logger.debug('+++ onDelete() user +++ deleteUserResult.data:', deleteUserResult.data);
                if (!!deleteUserResult.data?.deleteUser) {
                    toast(`Пользователь ${store.tempUser?.username} удален`, {type: 'success'});
                    reloadUsersCallback();
                    store.setOpenUserDialog(false);
                } else {
                    showOnDeleteError();
                }
            })
            .catch((reason) => {
                logger.debug('+++ onDelete() +++ reason:', reason);
                showOnDeleteError(reason);
            });
    }

    const onChangeResourceSelected = useCallback((selectedUserResources: ITreeData[]) => {
        setUserSelectedResourcesData(selectedUserResources)
    }, []);

    const saveSelectedResources = (
        user: UserDto | IUser,
        userWorkspaces: IUserWorkspace[],
        userRegions: IUserRegion[],
        userMedOrgs: IUserMedOrg[],
        userDoctors: IUserDoctor[]
    ) => {
        Promise.all([
            saveUserWorkspaces({variables: {userId: user.id, userWorkspaces: userWorkspaces as any}}),
            saveUserRegions({variables: {userId: user.id, userRegions: userRegions as any}}),
            saveUserMedOrgs({variables: {userId: user.id, userMedOrgs: userMedOrgs as any}}),
            saveUserDoctors({variables: {userId: user.id, userDoctors: userDoctors as any}})
        ])
            .then(([resultUserWorkspaces, resultUserRegions, resultUserMedOrgs, resultUserDoctors]) => {
                toast(`Ресурсы пользователя ${user.username} сохранены`, {type: 'success'});
                toast('Обновление ресурсов пользователя вступит в силу после повторной авторизации', {type: 'info'});
                onClose();
                reloadUsersCallback();
            })
            .catch((reason) => {
                logger.debug('+++ saveSelectedResources() +++ reason:', reason);
                toast(`Ошибка сохранения ресурсов пользователя`, {type: 'error'});
            })
            .finally(() => {
                store.isLoading = false;
            });
    }

    const onSave = () => {

        if (
            store!.tempUser!.username === store!.user!.username
            && store!.tempUser!.firstname === store!.user!.firstname
            && store!.tempUser!.lastname === store!.user!.lastname
            && !store!.tempUser!.password
        ) {
            const userId = store!.user!.id;
            saveSelectedResources(
                store.user!,
                getWorkspacesDTO(checkedWorkspaces, userId),
                ResourceTreeService.getUserRegionDTO(userSelectedResourcesData, userId),
                ResourceTreeService.getUserMedOrgDTO(userSelectedResourcesData, userId),
                ResourceTreeService.getUserDoctorDTO(userSelectedResourcesData, userId)
            );
            return;
        }

        store.isLoading = true;

        const userInput: UserInput = {
            id: store!.tempUser!.id,
            username: store!.tempUser!.username,
            firstname: store!.tempUser!.firstname,
            lastname: store!.tempUser!.lastname,
            password: store!.tempUser!.password,
        };

        logger.debug('+++ onSave() user +++ userInput:', userInput);
        saveUser({variables: {user: userInput}})
            .then((savedUser) => {
                if (!!savedUser.data?.saveUser.id) {
                    toast(`Пользователь ${savedUser.data?.saveUser.username} сохранен`, {type: 'success'});

                    const userId = savedUser.data?.saveUser.id;

                    saveSelectedResources(
                        savedUser.data?.saveUser,
                        getWorkspacesDTO(checkedWorkspaces, userId),
                        ResourceTreeService.getUserRegionDTO(userSelectedResourcesData, userId),
                        ResourceTreeService.getUserMedOrgDTO(userSelectedResourcesData, userId),
                        ResourceTreeService.getUserDoctorDTO(userSelectedResourcesData, userId)
                    );

                } else {
                    toast(`Ошибка сохранения пользователя`, {type: 'error'});
                }
            })
            .catch((reason) => {
                logger.debug('+++ saveUser() +++ reason:', reason);
                toast(`Ошибка сохранения пользователя`, {type: 'error'});
            })
            .finally(() => {
                store.isLoading = false;
            });
    }

    const handleCheckedWorkspacesToggle = (value: number) => () => {
        const currentIndex = checkedWorkspaces.indexOf(value);
        const newCheckedWorkspaces = [...checkedWorkspaces];

        if (currentIndex === -1) {
            newCheckedWorkspaces.push(value);
        } else {
            newCheckedWorkspaces.splice(currentIndex, 1);
        }

        setCheckedWorkspaces(newCheckedWorkspaces);
    };

    return (
        <Fragment>
            <StyledDialog
                disableEscapeKeyDown={true}
                fullScreen
                scroll={'paper'}
                open={store.openUserDialog}
                onClose={onClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <Typography
                            sx={{ ml: 0, flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            variant="body1"
                            component="div"
                        >
                            {store?.tempUser?.id ? '' : 'Новый пользователь  '}
                            {store?.tempUser?.username}
                            {
                                (!!store?.tempUser?.lastname || !!store?.tempUser?.firstname)
                                    ? (': ' + (store?.tempUser?.lastname ?? '') + (store?.tempUser?.firstname ? ' ' : '') + (store?.tempUser?.firstname ?? ''))
                                    : ''}
                        </Typography>
                    </Toolbar>
                </AppBar>

                <DialogContent dividers={true} className={clsx({'disabled': store.isLoading})} >
                    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'ru-RU'} >
                        <Grid2 container spacing={2} sx={{height: '100%'}}>
                            <Grid2
                                xs={12}
                                sm={5}
                                md={5}
                                lg={4}
                                xl={4}
                                sx={{ display: "flex", flexDirection: 'column'}}
                            >
                                <Card>
                                    <CardHeader
                                        sx={{pb: 0, pt: 1}}
                                        title={<Typography variant={'body1'} sx={{fontWeight: 600}}>Общая информация</Typography>}
                                    />
                                    <CardContent sx={{pb: '0 !important'}}>
                                        <FormControl
                                            fullWidth
                                            sx={{mb: 2}}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'Логин'}
                                                variant={'standard'}
                                                inputProps={{
                                                    maxLength: 50,
                                                }}
                                                sx={{
                                                    '& input': {
                                                        textAlign: 'left'
                                                    }
                                                }}
                                                size={'small'}
                                                value={store?.tempUser?.username || ''}
                                                onChange={(e) => {
                                                    const regex = /^[0-9a-zA-Z\-@._]+$/;
                                                    if (e.target.value == "" || regex.test(e.target.value)) {
                                                        store!.tempUser!.username = e.target.value;
                                                    }
                                                }}
                                            />
                                        </FormControl>

                                        <FormControl
                                            fullWidth
                                            sx={{mb: 2}}
                                        >
                                            <TextField
                                                fullWidth
                                                label={'Фамилия'}
                                                variant={'standard'}
                                                inputProps={{
                                                    maxLength: 50,
                                                }}
                                                sx={{
                                                    '& input': {
                                                        textAlign: 'left'
                                                    }
                                                }}
                                                size={'small'}
                                                value={store?.tempUser?.lastname || ''}
                                                onChange={(e) => {
                                                    const regex = /^[а-яА-Я\- ]+$/;
                                                    if (e.target.value == "" || regex.test(e.target.value)) {
                                                        store!.tempUser!.lastname = e.target.value;
                                                    }
                                                }}
                                            />
                                        </FormControl>

                                        <FormControl
                                            fullWidth
                                            sx={{mb: 2}}
                                        >
                                            <TextField
                                                label={'Имя'}
                                                variant={'standard'}
                                                inputProps={{
                                                    maxLength: 50,
                                                }}
                                                sx={{
                                                    '& input': {
                                                        textAlign: 'left'
                                                    }
                                                }}
                                                size={'small'}
                                                value={store?.tempUser?.firstname || ''}
                                                onChange={(e) => {
                                                    const regex = /^[а-яА-Я\- ]+$/;
                                                    if (e.target.value == "" || regex.test(e.target.value)) {
                                                        store!.tempUser!.firstname = e.target.value;
                                                    }
                                                }}
                                            />
                                        </FormControl>

                                        <FormControl
                                            fullWidth
                                            sx={{mb: 2}}
                                        >
                                            <TextField
                                                label={'Пароль'}
                                                variant={'standard'}
                                                type={showPassword ? 'text' : 'password'}
                                                autoComplete="new-password"
                                                sx={{
                                                    '& input': {
                                                        textAlign: 'left'
                                                    }
                                                }}
                                                size={'small'}
                                                inputProps={{
                                                    maxLength: 50,
                                                }}
                                                InputProps={{
                                                    endAdornment:
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                // onClick={() => setShowPassword(!showPassword)}
                                                                onMouseDown={() => setShowPassword(true)}
                                                                onPointerDown={() => setShowPassword(true)}
                                                                onMouseUp={() => setShowPassword(false)}
                                                                onPointerUp={() => setShowPassword(false)}
                                                                onMouseLeave={() => setShowPassword(false)}
                                                                onPointerLeave={() => setShowPassword(false)}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                }}
                                                value={store?.tempUser?.password || ''}
                                                onChange={(e) => {
                                                    store!.tempUser!.password = e.target.value;
                                                }}
                                            />
                                        </FormControl>
                                    </CardContent>
                                </Card>

                                <Card sx={{mt: 2, flexGrow: 1}}>
                                    <CardHeader
                                        sx={{pb: 0, pt: 1}}
                                        title={<Typography variant={'body1'} sx={{fontWeight: 600}}>Наборы дашбордов</Typography>}
                                    />
                                    <CardContent sx={{pb: '0 !important'}}>
                                        <List sx={{ width: '100%', height: 'calc(100vh - 524px)', overflowY: 'auto' }}>
                                            {datatableWorkspaceData.map((value) => {
                                                const labelId = `checkbox-list-label-${value.id}`;

                                                return (
                                                    <ListItem
                                                        key={value.id}
                                                        disablePadding
                                                    >
                                                        <ListItemButton role={undefined}
                                                                        onClick={handleCheckedWorkspacesToggle(value.id)}
                                                                        dense
                                                                        sx={{paddingLeft: '4px'}}
                                                        >
                                                                <CustomCheckbox
                                                                    isChecked={checkedWorkspaces.indexOf(value.id) !== -1}
                                                                    label={WorkspaceNamingService.getWsName(value.slug)}
                                                                    id={labelId}
                                                                ></CustomCheckbox>
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid2>
                            <Grid2
                                xs={12}
                                sm={7}
                                md={7}
                                lg={8}
                                xl={8}
                                sx={{ display: "flex", flexDirection: 'column'}}
                            >
                                <Card sx={{
                                    flexGrow: 1,
                                    maxHeight: 'calc(100vh - 155px)'
                                }}>
                                    <CardHeader
                                        sx={{pb: 0, pt: 1}}
                                        title={
                                            <Typography
                                                variant={'body1'}
                                                sx={{fontWeight: 600}}
                                            >Ресурсы</Typography>
                                        }
                                        // subheader={
                                        // <>
                                        //     <InfoOutlinedIcon />
                                        //     <Typography
                                        //         sx={{
                                        //             lineHeight: '.8rem',
                                        //             display: "inline-block",
                                        //             fontSize: ".8rem",
                                        //             color: "rgba(0, 0, 0, 0.6)"
                                        //     }}
                                        //     >Если оставить этот раздел пустым, то пользователю будут доступны все имеющиеся ресурсы</Typography>
                                        // </>
                                        // }
                                    />
                                    <CardContent sx={{pb: '0 !important', height: 'calc(100% - 36px)'}}>
                                        <RecursiveTreeView
                                            treeItems={treeDataStateMemo}
                                            selectedTreeItems={selectedTreeItemsMemo}
                                            onChangeSelected={onChangeResourceSelected}
                                            isLoadingTreeData={isLoadingTreeData}
                                        />
                                    </CardContent>
                                </Card>
                            </Grid2>
                        </Grid2>
                    </LocalizationProvider>
                </DialogContent>

                <Box sx={{ width: '100%', height: '4px' }}>
                    {store.isLoading && <LinearProgress sx={{height: '2px'}} />}
                </Box>

                <DialogActions className={clsx({'disabled': store.isLoading})}>
                    <Box
                        justifyContent={'space-between'}
                        display={'flex'}
                        flexGrow={1}
                    >
                        <Box display={'flex'}>
                            <Button
                                size={'small'}
                                variant={'contained'}
                                color={'warning'}
                                disabled={!store.tempUser?.id}
                                onClick={onDelete}
                            >
                                Удалить
                            </Button>
                        </Box>
                        <Box display={'flex'}>
                            <Button
                                size={'small'}
                                variant={'contained'}
                                color={'primary'}
                                onClick={onClose}
                                sx={{ml: 1}}
                            >
                                Закрыть
                            </Button>
                            <Button
                                size={'small'}
                                variant={'contained'}
                                color={'success'}
                                onClick={onSave}
                                sx={{ml: 1, color: 'white'}}
                                disabled={
                                    (!store?.tempUser?.id && !store?.tempUser?.password)
                                        || !store?.tempUser?.username
                                }
                            >
                                Сохранить
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </StyledDialog>
        </Fragment>
    );
});

export default UserDialog;
