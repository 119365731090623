import {IStatsMenuItem} from "../../models/stats/IStatsMenuItem";

export const STATS_MENU_CONFIG: IStatsMenuItem[] = [
    {
        "path": "report",
        "name": "Генерация отчетов",
        "children": [
            {
                "path": "static",
                "name": "Таблица отчетов",
            },
            {
                "path": "dynamic",
                "name": "Посуточная динамика генерации отчетов",
            },
        ]
    },
    {
        "path": "document",
        "name": "Обработка входящих документов",
        "children": [
            {
                "path": "static",
                "name": "Таблица документов",
            },
            {
                "path": "dynamic",
                "name": "Посуточная динамика загрузки документов",
            },
        ]
    },
]