import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type DoctorDto = {
  readonly __typename?: 'DoctorDto';
  readonly name?: Maybe<Scalars['String']>;
};

export type DocumentDto = {
  readonly __typename?: 'DocumentDto';
  readonly created_at?: Maybe<Scalars['String']>;
  readonly error_descr?: Maybe<Scalars['String']>;
  readonly id?: Maybe<Scalars['Int']>;
  readonly message_uuid?: Maybe<Scalars['String']>;
  readonly patient_uuid?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
};

export type DocumentStatusCountDto = {
  readonly __typename?: 'DocumentStatusCountDto';
  readonly count?: Maybe<Scalars['Int']>;
  readonly status?: Maybe<Scalars['String']>;
};

export type MedOrgDto = {
  readonly __typename?: 'MedOrgDto';
  readonly doctor?: Maybe<ReadonlyArray<Maybe<DoctorDto>>>;
  readonly name?: Maybe<Scalars['String']>;
};

export type Mutation = {
  readonly __typename?: 'Mutation';
  readonly deleteUser: Scalars['Boolean'];
  readonly saveUser: UserDto;
  readonly saveUserDoctors?: Maybe<ReadonlyArray<Maybe<UserDoctorDto>>>;
  readonly saveUserMedOrgs?: Maybe<ReadonlyArray<Maybe<UserMedOrgDto>>>;
  readonly saveUserRegions?: Maybe<ReadonlyArray<Maybe<UserRegionDto>>>;
  readonly saveUserWorkspaces?: Maybe<ReadonlyArray<Maybe<UserWorkspaceDto>>>;
};


export type MutationDeleteUserArgs = {
  userId: Scalars['Int'];
};


export type MutationSaveUserArgs = {
  user?: InputMaybe<UserInput>;
};


export type MutationSaveUserDoctorsArgs = {
  userDoctors?: InputMaybe<ReadonlyArray<InputMaybe<UserDoctorInput>>>;
  userId: Scalars['Int'];
};


export type MutationSaveUserMedOrgsArgs = {
  userId: Scalars['Int'];
  userMedOrgs?: InputMaybe<ReadonlyArray<InputMaybe<UserMedOrgInput>>>;
};


export type MutationSaveUserRegionsArgs = {
  userId: Scalars['Int'];
  userRegions?: InputMaybe<ReadonlyArray<InputMaybe<UserRegionInput>>>;
};


export type MutationSaveUserWorkspacesArgs = {
  userId: Scalars['Int'];
  userWorkspaces?: InputMaybe<ReadonlyArray<InputMaybe<UserWorkspaceInput>>>;
};

export type PagingRequest = {
  readonly page?: InputMaybe<Scalars['Int']>;
  readonly size?: InputMaybe<Scalars['Int']>;
};

export type PagingResponse = {
  readonly __typename?: 'PagingResponse';
  readonly totalElements?: Maybe<Scalars['Int']>;
};

export type Query = {
  readonly __typename?: 'Query';
  readonly getDocumentStatusCount: ReadonlyArray<Maybe<DocumentStatusCountDto>>;
  readonly getDocumentsByParams: GetDocumentsByParamsResponse;
  readonly getListUser?: Maybe<ReadonlyArray<Maybe<UserDto>>>;
  readonly getListWorkspace?: Maybe<ReadonlyArray<Maybe<WorkspaceDto>>>;
  readonly getReportStatusCount: ReadonlyArray<Maybe<ReportStatusCountDto>>;
  readonly getReportsByParams: GetReportsByParamsResponse;
  readonly getTreeResources?: Maybe<ResourceDto>;
  readonly getUser: UserDto;
  readonly getUserDoctors?: Maybe<ReadonlyArray<Maybe<UserDoctorDto>>>;
  readonly getUserMedOrgs?: Maybe<ReadonlyArray<Maybe<UserMedOrgDto>>>;
  readonly getUserRegions?: Maybe<ReadonlyArray<Maybe<UserRegionDto>>>;
  readonly getUserWorkspaces?: Maybe<ReadonlyArray<Maybe<UserWorkspaceDto>>>;
  readonly signIn: SignInDto;
};

export type QuerySignInArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type SignInDto = {
  readonly __typename?: 'SignInDto';
  readonly jwt?: Maybe<Scalars['String']>;
  readonly username?: Maybe<Scalars['String']>;
};

export type SignInQueryVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;

export type SignInQuery = { readonly __typename?: 'Query', readonly signIn: { readonly __typename?: 'SignInDto', readonly jwt?: string | null, readonly username?: string | null } };
export const SignInDocument = gql`
  query signIn($username: String!, $password: String!) {
    signIn(username: $username, password: $password) {
      jwt
      username
    }
  }
`;

/**
 * __useSignInQuery__
 *
 * To run a query within a React component, call `useSignInQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignInQuery({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInQuery(baseOptions: Apollo.QueryHookOptions<SignInQuery, SignInQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<SignInQuery, SignInQueryVariables>(SignInDocument, options);
}
export function useSignInLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignInQuery, SignInQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<SignInQuery, SignInQueryVariables>(SignInDocument, options);
}
export type SignInQueryHookResult = ReturnType<typeof useSignInQuery>;
export type SignInLazyQueryHookResult = ReturnType<typeof useSignInLazyQuery>;
export type SignInQueryResult = Apollo.QueryResult<SignInQuery, SignInQueryVariables>;


export type QueryGetDocumentStatusCountArgs = {
  from?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  to?: InputMaybe<Scalars['String']>;
};


export type QueryGetDocumentsByParamsArgs = {
  from?: InputMaybe<Scalars['String']>;
  paging?: InputMaybe<PagingRequest>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  to?: InputMaybe<Scalars['String']>;
};


export type QueryGetReportStatusCountArgs = {
  from?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  to?: InputMaybe<Scalars['String']>;
};


export type QueryGetReportsByParamsArgs = {
  from?: InputMaybe<Scalars['String']>;
  paging?: InputMaybe<PagingRequest>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>>>;
  to?: InputMaybe<Scalars['String']>;
};


export type QueryGetUserArgs = {
  userId: Scalars['Int'];
};


export type QueryGetUserDoctorsArgs = {
  userId: Scalars['Int'];
};


export type QueryGetUserMedOrgsArgs = {
  userId: Scalars['Int'];
};


export type QueryGetUserRegionsArgs = {
  userId: Scalars['Int'];
};


export type QueryGetUserWorkspacesArgs = {
  userId: Scalars['Int'];
};

export type RegionDto = {
  readonly __typename?: 'RegionDto';
  readonly medOrg?: Maybe<ReadonlyArray<Maybe<MedOrgDto>>>;
  readonly name?: Maybe<Scalars['String']>;
};

export type ReportDto = {
  readonly __typename?: 'ReportDto';
  readonly descr?: Maybe<Scalars['String']>;
  readonly modified_date?: Maybe<Scalars['String']>;
  readonly patient_id?: Maybe<Scalars['String']>;
  readonly status?: Maybe<Scalars['String']>;
  readonly uuid: Scalars['String'];
};

export type ReportStatusCountDto = {
  readonly __typename?: 'ReportStatusCountDto';
  readonly count?: Maybe<Scalars['Int']>;
  readonly status?: Maybe<Scalars['String']>;
};

export type ResourceDto = {
  readonly __typename?: 'ResourceDto';
  readonly region?: Maybe<ReadonlyArray<Maybe<RegionDto>>>;
};

export type UserDoctorDto = {
  readonly __typename?: 'UserDoctorDto';
  readonly doctorId?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['Int']>;
};

export type UserDoctorInput = {
  readonly doctorId?: InputMaybe<Scalars['String']>;
  readonly userId?: InputMaybe<Scalars['Int']>;
};

/**  Схема запросов пользователей */
export type UserDto = {
  readonly __typename?: 'UserDto';
  readonly doctor?: Maybe<ReadonlyArray<Maybe<UserDoctorDto>>>;
  readonly enabled?: Maybe<Scalars['Boolean']>;
  readonly firstname?: Maybe<Scalars['String']>;
  readonly id: Scalars['Int'];
  readonly lastname?: Maybe<Scalars['String']>;
  readonly medOrg?: Maybe<ReadonlyArray<Maybe<UserMedOrgDto>>>;
  readonly region?: Maybe<ReadonlyArray<Maybe<UserRegionDto>>>;
  readonly username?: Maybe<Scalars['String']>;
  readonly workspace?: Maybe<ReadonlyArray<Maybe<WorkspaceDto>>>;
};

export type UserInput = {
  readonly enabled?: InputMaybe<Scalars['Boolean']>;
  readonly firstname?: InputMaybe<Scalars['String']>;
  readonly id?: InputMaybe<Scalars['Int']>;
  readonly lastname?: InputMaybe<Scalars['String']>;
  readonly password?: InputMaybe<Scalars['String']>;
  readonly username?: InputMaybe<Scalars['String']>;
};

export type UserMedOrgDto = {
  readonly __typename?: 'UserMedOrgDto';
  readonly medOrgId?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['Int']>;
};

export type UserMedOrgInput = {
  readonly medOrgId?: InputMaybe<Scalars['String']>;
  readonly userId?: InputMaybe<Scalars['Int']>;
};

export type UserRegionDto = {
  readonly __typename?: 'UserRegionDto';
  readonly regionId?: Maybe<Scalars['String']>;
  readonly userId?: Maybe<Scalars['Int']>;
};

export type UserRegionInput = {
  readonly regionId?: InputMaybe<Scalars['String']>;
  readonly userId?: InputMaybe<Scalars['Int']>;
};

export type UserWorkspaceDto = {
  readonly __typename?: 'UserWorkspaceDto';
  readonly userId?: Maybe<Scalars['Int']>;
  readonly workspaceId?: Maybe<Scalars['Int']>;
};

export type UserWorkspaceInput = {
  readonly userId?: InputMaybe<Scalars['Int']>;
  readonly workspaceId?: InputMaybe<Scalars['String']>;
};

/**  Схема запросов Dashboard */
export type WorkspaceDto = {
  readonly __typename?: 'WorkspaceDto';
  readonly id: Scalars['Int'];
  readonly slug?: Maybe<Scalars['String']>;
};

export type GetDocumentsByParamsResponse = {
  readonly __typename?: 'getDocumentsByParamsResponse';
  readonly documents?: Maybe<ReadonlyArray<Maybe<DocumentDto>>>;
  readonly paging?: Maybe<PagingResponse>;
};

export type GetReportsByParamsResponse = {
  readonly __typename?: 'getReportsByParamsResponse';
  readonly paging?: Maybe<PagingResponse>;
  readonly reports?: Maybe<ReadonlyArray<Maybe<ReportDto>>>;
};

export type GetListWorkspaceQueryVariables = Exact<{ [key: string]: never; }>;


export type GetListWorkspaceQuery = { readonly __typename?: 'Query', readonly getListWorkspace?: ReadonlyArray<{ readonly __typename?: 'WorkspaceDto', readonly id: number, readonly slug?: string | null } | null> | null };

export type SaveUserWorkspacesMutationVariables = Exact<{
  userId: Scalars['Int'];
  userWorkspaces: ReadonlyArray<InputMaybe<UserWorkspaceInput>> | InputMaybe<UserWorkspaceInput>;
}>;


export type SaveUserWorkspacesMutation = { readonly __typename?: 'Mutation', readonly saveUserWorkspaces?: ReadonlyArray<{ readonly __typename?: 'UserWorkspaceDto', readonly userId?: number | null, readonly workspaceId?: number | null } | null> | null };

export type GetTreeResourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTreeResourcesQuery = { readonly __typename?: 'Query', readonly getTreeResources?: { readonly __typename?: 'ResourceDto', readonly region?: ReadonlyArray<{ readonly __typename?: 'RegionDto', readonly name?: string | null, readonly medOrg?: ReadonlyArray<{ readonly __typename?: 'MedOrgDto', readonly name?: string | null, readonly doctor?: ReadonlyArray<{ readonly __typename?: 'DoctorDto', readonly name?: string | null } | null> | null } | null> | null } | null> | null } | null };

export type GetUserRegionsQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type GetUserRegionsQuery = { readonly __typename?: 'Query', readonly getUserRegions?: ReadonlyArray<{ readonly __typename?: 'UserRegionDto', readonly userId?: number | null, readonly regionId?: string | null } | null> | null };

export type SaveUserRegionsMutationVariables = Exact<{
  userId: Scalars['Int'];
  userRegions: ReadonlyArray<InputMaybe<UserRegionInput>> | InputMaybe<UserRegionInput>;
}>;


export type SaveUserRegionsMutation = { readonly __typename?: 'Mutation', readonly saveUserRegions?: ReadonlyArray<{ readonly __typename?: 'UserRegionDto', readonly userId?: number | null, readonly regionId?: string | null } | null> | null };

export type GetUserMedOrgsQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type GetUserMedOrgsQuery = { readonly __typename?: 'Query', readonly getUserMedOrgs?: ReadonlyArray<{ readonly __typename?: 'UserMedOrgDto', readonly userId?: number | null, readonly medOrgId?: string | null } | null> | null };

export type SaveUserMedOrgsMutationVariables = Exact<{
  userId: Scalars['Int'];
  userMedOrgs: ReadonlyArray<InputMaybe<UserMedOrgInput>> | InputMaybe<UserMedOrgInput>;
}>;


export type SaveUserMedOrgsMutation = { readonly __typename?: 'Mutation', readonly saveUserMedOrgs?: ReadonlyArray<{ readonly __typename?: 'UserMedOrgDto', readonly userId?: number | null, readonly medOrgId?: string | null } | null> | null };

export type GetUserDoctorsQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type GetUserDoctorsQuery = { readonly __typename?: 'Query', readonly getUserDoctors?: ReadonlyArray<{ readonly __typename?: 'UserDoctorDto', readonly userId?: number | null, readonly doctorId?: string | null } | null> | null };

export type SaveUserDoctorsMutationVariables = Exact<{
  userId: Scalars['Int'];
  userDoctors: ReadonlyArray<InputMaybe<UserDoctorInput>> | InputMaybe<UserDoctorInput>;
}>;


export type SaveUserDoctorsMutation = { readonly __typename?: 'Mutation', readonly saveUserDoctors?: ReadonlyArray<{ readonly __typename?: 'UserDoctorDto', readonly userId?: number | null, readonly doctorId?: string | null } | null> | null };

export type GetDocumentStatusCountQueryVariables = Exact<{
  from?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetDocumentStatusCountQuery = { readonly __typename?: 'Query', readonly getDocumentStatusCount: ReadonlyArray<{ readonly __typename?: 'DocumentStatusCountDto', readonly count?: number | null, readonly status?: string | null } | null> };

export type GetDocumentsByParamsQueryVariables = Exact<{
  from?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  paging?: InputMaybe<PagingRequest>;
}>;


export type GetDocumentsByParamsQuery = { readonly __typename?: 'Query', readonly getDocumentsByParams: { readonly __typename?: 'getDocumentsByParamsResponse', readonly paging?: { readonly __typename?: 'PagingResponse', readonly totalElements?: number | null } | null, readonly documents?: ReadonlyArray<{ readonly __typename?: 'DocumentDto', readonly id?: number | null, readonly message_uuid?: string | null, readonly patient_uuid?: string | null, readonly status?: string | null, readonly error_descr?: string | null, readonly created_at?: string | null } | null> | null } };

export type GetReportStatusCountQueryVariables = Exact<{
  from?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type GetReportStatusCountQuery = { readonly __typename?: 'Query', readonly getReportStatusCount: ReadonlyArray<{ readonly __typename?: 'ReportStatusCountDto', readonly count?: number | null, readonly status?: string | null } | null> };

export type GetReportsByParamsQueryVariables = Exact<{
  from?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  paging?: InputMaybe<PagingRequest>;
}>;


export type GetReportsByParamsQuery = { readonly __typename?: 'Query', readonly getReportsByParams: { readonly __typename?: 'getReportsByParamsResponse', readonly paging?: { readonly __typename?: 'PagingResponse', readonly totalElements?: number | null } | null, readonly reports?: ReadonlyArray<{ readonly __typename?: 'ReportDto', readonly uuid: string, readonly patient_id?: string | null, readonly status?: string | null, readonly descr?: string | null, readonly modified_date?: string | null } | null> | null } };

export type GetListUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetListUserQuery = { readonly __typename?: 'Query', readonly getListUser?: ReadonlyArray<{ readonly __typename?: 'UserDto', readonly id: number, readonly username?: string | null, readonly firstname?: string | null, readonly lastname?: string | null, readonly enabled?: boolean | null, readonly workspace?: ReadonlyArray<{ readonly __typename?: 'WorkspaceDto', readonly id: number, readonly slug?: string | null } | null> | null, readonly region?: ReadonlyArray<{ readonly __typename?: 'UserRegionDto', readonly regionId?: string | null } | null> | null, readonly medOrg?: ReadonlyArray<{ readonly __typename?: 'UserMedOrgDto', readonly medOrgId?: string | null } | null> | null, readonly doctor?: ReadonlyArray<{ readonly __typename?: 'UserDoctorDto', readonly doctorId?: string | null } | null> | null } | null> | null };

export type GetUserQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type GetUserQuery = { readonly __typename?: 'Query', readonly getUser: { readonly __typename?: 'UserDto', readonly id: number, readonly username?: string | null, readonly firstname?: string | null, readonly lastname?: string | null, readonly enabled?: boolean | null } };

export type SaveUserMutationVariables = Exact<{
  user: UserInput;
}>;


export type SaveUserMutation = { readonly __typename?: 'Mutation', readonly saveUser: { readonly __typename?: 'UserDto', readonly id: number, readonly username?: string | null, readonly firstname?: string | null, readonly lastname?: string | null, readonly enabled?: boolean | null } };

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type DeleteUserMutation = { readonly __typename?: 'Mutation', readonly deleteUser: boolean };


export const GetListWorkspaceDocument = gql`
    query getListWorkspace {
  getListWorkspace {
    id
    slug
  }
}
    `;

/**
 * __useGetListWorkspaceQuery__
 *
 * To run a query within a React component, call `useGetListWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListWorkspaceQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetListWorkspaceQuery(baseOptions?: Apollo.QueryHookOptions<GetListWorkspaceQuery, GetListWorkspaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListWorkspaceQuery, GetListWorkspaceQueryVariables>(GetListWorkspaceDocument, options);
      }
export function useGetListWorkspaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListWorkspaceQuery, GetListWorkspaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListWorkspaceQuery, GetListWorkspaceQueryVariables>(GetListWorkspaceDocument, options);
        }
export type GetListWorkspaceQueryHookResult = ReturnType<typeof useGetListWorkspaceQuery>;
export type GetListWorkspaceLazyQueryHookResult = ReturnType<typeof useGetListWorkspaceLazyQuery>;
export type GetListWorkspaceQueryResult = Apollo.QueryResult<GetListWorkspaceQuery, GetListWorkspaceQueryVariables>;
export const SaveUserWorkspacesDocument = gql`
    mutation saveUserWorkspaces($userId: Int!, $userWorkspaces: [UserWorkspaceInput]!) {
  saveUserWorkspaces(userId: $userId, userWorkspaces: $userWorkspaces) {
    userId
    workspaceId
  }
}
    `;
export type SaveUserWorkspacesMutationFn = Apollo.MutationFunction<SaveUserWorkspacesMutation, SaveUserWorkspacesMutationVariables>;

/**
 * __useSaveUserWorkspacesMutation__
 *
 * To run a mutation, you first call `useSaveUserWorkspacesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserWorkspacesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserWorkspacesMutation, { data, loading, error }] = useSaveUserWorkspacesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userWorkspaces: // value for 'userWorkspaces'
 *   },
 * });
 */
export function useSaveUserWorkspacesMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserWorkspacesMutation, SaveUserWorkspacesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserWorkspacesMutation, SaveUserWorkspacesMutationVariables>(SaveUserWorkspacesDocument, options);
      }
export type SaveUserWorkspacesMutationHookResult = ReturnType<typeof useSaveUserWorkspacesMutation>;
export type SaveUserWorkspacesMutationResult = Apollo.MutationResult<SaveUserWorkspacesMutation>;
export type SaveUserWorkspacesMutationOptions = Apollo.BaseMutationOptions<SaveUserWorkspacesMutation, SaveUserWorkspacesMutationVariables>;
export const GetTreeResourcesDocument = gql`
    query getTreeResources {
  getTreeResources {
    region {
      name
      medOrg {
        name
        doctor {
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetTreeResourcesQuery__
 *
 * To run a query within a React component, call `useGetTreeResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTreeResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTreeResourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTreeResourcesQuery(baseOptions?: Apollo.QueryHookOptions<GetTreeResourcesQuery, GetTreeResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTreeResourcesQuery, GetTreeResourcesQueryVariables>(GetTreeResourcesDocument, options);
      }
export function useGetTreeResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTreeResourcesQuery, GetTreeResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTreeResourcesQuery, GetTreeResourcesQueryVariables>(GetTreeResourcesDocument, options);
        }
export type GetTreeResourcesQueryHookResult = ReturnType<typeof useGetTreeResourcesQuery>;
export type GetTreeResourcesLazyQueryHookResult = ReturnType<typeof useGetTreeResourcesLazyQuery>;
export type GetTreeResourcesQueryResult = Apollo.QueryResult<GetTreeResourcesQuery, GetTreeResourcesQueryVariables>;
export const GetUserRegionsDocument = gql`
    query getUserRegions($userId: Int!) {
  getUserRegions(userId: $userId) {
    userId
    regionId
  }
}
    `;

/**
 * __useGetUserRegionsQuery__
 *
 * To run a query within a React component, call `useGetUserRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserRegionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserRegionsQuery(baseOptions: Apollo.QueryHookOptions<GetUserRegionsQuery, GetUserRegionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserRegionsQuery, GetUserRegionsQueryVariables>(GetUserRegionsDocument, options);
      }
export function useGetUserRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserRegionsQuery, GetUserRegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserRegionsQuery, GetUserRegionsQueryVariables>(GetUserRegionsDocument, options);
        }
export type GetUserRegionsQueryHookResult = ReturnType<typeof useGetUserRegionsQuery>;
export type GetUserRegionsLazyQueryHookResult = ReturnType<typeof useGetUserRegionsLazyQuery>;
export type GetUserRegionsQueryResult = Apollo.QueryResult<GetUserRegionsQuery, GetUserRegionsQueryVariables>;
export const SaveUserRegionsDocument = gql`
    mutation saveUserRegions($userId: Int!, $userRegions: [UserRegionInput]!) {
  saveUserRegions(userId: $userId, userRegions: $userRegions) {
    userId
    regionId
  }
}
    `;
export type SaveUserRegionsMutationFn = Apollo.MutationFunction<SaveUserRegionsMutation, SaveUserRegionsMutationVariables>;

/**
 * __useSaveUserRegionsMutation__
 *
 * To run a mutation, you first call `useSaveUserRegionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserRegionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserRegionsMutation, { data, loading, error }] = useSaveUserRegionsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userRegions: // value for 'userRegions'
 *   },
 * });
 */
export function useSaveUserRegionsMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserRegionsMutation, SaveUserRegionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserRegionsMutation, SaveUserRegionsMutationVariables>(SaveUserRegionsDocument, options);
      }
export type SaveUserRegionsMutationHookResult = ReturnType<typeof useSaveUserRegionsMutation>;
export type SaveUserRegionsMutationResult = Apollo.MutationResult<SaveUserRegionsMutation>;
export type SaveUserRegionsMutationOptions = Apollo.BaseMutationOptions<SaveUserRegionsMutation, SaveUserRegionsMutationVariables>;
export const GetUserMedOrgsDocument = gql`
    query getUserMedOrgs($userId: Int!) {
  getUserMedOrgs(userId: $userId) {
    userId
    medOrgId
  }
}
    `;

/**
 * __useGetUserMedOrgsQuery__
 *
 * To run a query within a React component, call `useGetUserMedOrgsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserMedOrgsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserMedOrgsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserMedOrgsQuery(baseOptions: Apollo.QueryHookOptions<GetUserMedOrgsQuery, GetUserMedOrgsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserMedOrgsQuery, GetUserMedOrgsQueryVariables>(GetUserMedOrgsDocument, options);
      }
export function useGetUserMedOrgsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserMedOrgsQuery, GetUserMedOrgsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserMedOrgsQuery, GetUserMedOrgsQueryVariables>(GetUserMedOrgsDocument, options);
        }
export type GetUserMedOrgsQueryHookResult = ReturnType<typeof useGetUserMedOrgsQuery>;
export type GetUserMedOrgsLazyQueryHookResult = ReturnType<typeof useGetUserMedOrgsLazyQuery>;
export type GetUserMedOrgsQueryResult = Apollo.QueryResult<GetUserMedOrgsQuery, GetUserMedOrgsQueryVariables>;
export const SaveUserMedOrgsDocument = gql`
    mutation saveUserMedOrgs($userId: Int!, $userMedOrgs: [UserMedOrgInput]!) {
  saveUserMedOrgs(userId: $userId, userMedOrgs: $userMedOrgs) {
    userId
    medOrgId
  }
}
    `;
export type SaveUserMedOrgsMutationFn = Apollo.MutationFunction<SaveUserMedOrgsMutation, SaveUserMedOrgsMutationVariables>;

/**
 * __useSaveUserMedOrgsMutation__
 *
 * To run a mutation, you first call `useSaveUserMedOrgsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserMedOrgsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserMedOrgsMutation, { data, loading, error }] = useSaveUserMedOrgsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userMedOrgs: // value for 'userMedOrgs'
 *   },
 * });
 */
export function useSaveUserMedOrgsMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserMedOrgsMutation, SaveUserMedOrgsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserMedOrgsMutation, SaveUserMedOrgsMutationVariables>(SaveUserMedOrgsDocument, options);
      }
export type SaveUserMedOrgsMutationHookResult = ReturnType<typeof useSaveUserMedOrgsMutation>;
export type SaveUserMedOrgsMutationResult = Apollo.MutationResult<SaveUserMedOrgsMutation>;
export type SaveUserMedOrgsMutationOptions = Apollo.BaseMutationOptions<SaveUserMedOrgsMutation, SaveUserMedOrgsMutationVariables>;
export const GetUserDoctorsDocument = gql`
    query getUserDoctors($userId: Int!) {
  getUserDoctors(userId: $userId) {
    userId
    doctorId
  }
}
    `;

/**
 * __useGetUserDoctorsQuery__
 *
 * To run a query within a React component, call `useGetUserDoctorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDoctorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDoctorsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserDoctorsQuery(baseOptions: Apollo.QueryHookOptions<GetUserDoctorsQuery, GetUserDoctorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDoctorsQuery, GetUserDoctorsQueryVariables>(GetUserDoctorsDocument, options);
      }
export function useGetUserDoctorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDoctorsQuery, GetUserDoctorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDoctorsQuery, GetUserDoctorsQueryVariables>(GetUserDoctorsDocument, options);
        }
export type GetUserDoctorsQueryHookResult = ReturnType<typeof useGetUserDoctorsQuery>;
export type GetUserDoctorsLazyQueryHookResult = ReturnType<typeof useGetUserDoctorsLazyQuery>;
export type GetUserDoctorsQueryResult = Apollo.QueryResult<GetUserDoctorsQuery, GetUserDoctorsQueryVariables>;
export const SaveUserDoctorsDocument = gql`
    mutation saveUserDoctors($userId: Int!, $userDoctors: [UserDoctorInput]!) {
  saveUserDoctors(userId: $userId, userDoctors: $userDoctors) {
    userId
    doctorId
  }
}
    `;
export type SaveUserDoctorsMutationFn = Apollo.MutationFunction<SaveUserDoctorsMutation, SaveUserDoctorsMutationVariables>;

/**
 * __useSaveUserDoctorsMutation__
 *
 * To run a mutation, you first call `useSaveUserDoctorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserDoctorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserDoctorsMutation, { data, loading, error }] = useSaveUserDoctorsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userDoctors: // value for 'userDoctors'
 *   },
 * });
 */
export function useSaveUserDoctorsMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserDoctorsMutation, SaveUserDoctorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserDoctorsMutation, SaveUserDoctorsMutationVariables>(SaveUserDoctorsDocument, options);
      }
export type SaveUserDoctorsMutationHookResult = ReturnType<typeof useSaveUserDoctorsMutation>;
export type SaveUserDoctorsMutationResult = Apollo.MutationResult<SaveUserDoctorsMutation>;
export type SaveUserDoctorsMutationOptions = Apollo.BaseMutationOptions<SaveUserDoctorsMutation, SaveUserDoctorsMutationVariables>;
export const GetDocumentStatusCountDocument = gql`
    query getDocumentStatusCount($from: String, $to: String, $status: [String]) {
  getDocumentStatusCount(from: $from, to: $to, status: $status) {
    count
    status
  }
}
    `;

/**
 * __useGetDocumentStatusCountQuery__
 *
 * To run a query within a React component, call `useGetDocumentStatusCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentStatusCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentStatusCountQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetDocumentStatusCountQuery(baseOptions?: Apollo.QueryHookOptions<GetDocumentStatusCountQuery, GetDocumentStatusCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentStatusCountQuery, GetDocumentStatusCountQueryVariables>(GetDocumentStatusCountDocument, options);
      }
export function useGetDocumentStatusCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentStatusCountQuery, GetDocumentStatusCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentStatusCountQuery, GetDocumentStatusCountQueryVariables>(GetDocumentStatusCountDocument, options);
        }
export type GetDocumentStatusCountQueryHookResult = ReturnType<typeof useGetDocumentStatusCountQuery>;
export type GetDocumentStatusCountLazyQueryHookResult = ReturnType<typeof useGetDocumentStatusCountLazyQuery>;
export type GetDocumentStatusCountQueryResult = Apollo.QueryResult<GetDocumentStatusCountQuery, GetDocumentStatusCountQueryVariables>;
export const GetDocumentsByParamsDocument = gql`
    query getDocumentsByParams($from: String, $to: String, $status: [String], $paging: PagingRequest) {
  getDocumentsByParams(from: $from, to: $to, status: $status, paging: $paging) {
    paging {
      totalElements
    }
    documents {
      id
      message_uuid
      patient_uuid
      status
      error_descr
      created_at
    }
  }
}
    `;

/**
 * __useGetDocumentsByParamsQuery__
 *
 * To run a query within a React component, call `useGetDocumentsByParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsByParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsByParamsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      status: // value for 'status'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetDocumentsByParamsQuery(baseOptions?: Apollo.QueryHookOptions<GetDocumentsByParamsQuery, GetDocumentsByParamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentsByParamsQuery, GetDocumentsByParamsQueryVariables>(GetDocumentsByParamsDocument, options);
      }
export function useGetDocumentsByParamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentsByParamsQuery, GetDocumentsByParamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentsByParamsQuery, GetDocumentsByParamsQueryVariables>(GetDocumentsByParamsDocument, options);
        }
export type GetDocumentsByParamsQueryHookResult = ReturnType<typeof useGetDocumentsByParamsQuery>;
export type GetDocumentsByParamsLazyQueryHookResult = ReturnType<typeof useGetDocumentsByParamsLazyQuery>;
export type GetDocumentsByParamsQueryResult = Apollo.QueryResult<GetDocumentsByParamsQuery, GetDocumentsByParamsQueryVariables>;
export const GetReportStatusCountDocument = gql`
    query getReportStatusCount($from: String, $to: String, $status: [String]) {
  getReportStatusCount(from: $from, to: $to, status: $status) {
    count
    status
  }
}
    `;

/**
 * __useGetReportStatusCountQuery__
 *
 * To run a query within a React component, call `useGetReportStatusCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportStatusCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportStatusCountQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetReportStatusCountQuery(baseOptions?: Apollo.QueryHookOptions<GetReportStatusCountQuery, GetReportStatusCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportStatusCountQuery, GetReportStatusCountQueryVariables>(GetReportStatusCountDocument, options);
      }
export function useGetReportStatusCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportStatusCountQuery, GetReportStatusCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportStatusCountQuery, GetReportStatusCountQueryVariables>(GetReportStatusCountDocument, options);
        }
export type GetReportStatusCountQueryHookResult = ReturnType<typeof useGetReportStatusCountQuery>;
export type GetReportStatusCountLazyQueryHookResult = ReturnType<typeof useGetReportStatusCountLazyQuery>;
export type GetReportStatusCountQueryResult = Apollo.QueryResult<GetReportStatusCountQuery, GetReportStatusCountQueryVariables>;
export const GetReportsByParamsDocument = gql`
    query getReportsByParams($from: String, $to: String, $status: [String], $paging: PagingRequest) {
  getReportsByParams(from: $from, to: $to, status: $status, paging: $paging) {
    paging {
      totalElements
    }
    reports {
      uuid
      patient_id
      status
      descr
      modified_date
    }
  }
}
    `;

/**
 * __useGetReportsByParamsQuery__
 *
 * To run a query within a React component, call `useGetReportsByParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsByParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsByParamsQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      status: // value for 'status'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetReportsByParamsQuery(baseOptions?: Apollo.QueryHookOptions<GetReportsByParamsQuery, GetReportsByParamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportsByParamsQuery, GetReportsByParamsQueryVariables>(GetReportsByParamsDocument, options);
      }
export function useGetReportsByParamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportsByParamsQuery, GetReportsByParamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportsByParamsQuery, GetReportsByParamsQueryVariables>(GetReportsByParamsDocument, options);
        }
export type GetReportsByParamsQueryHookResult = ReturnType<typeof useGetReportsByParamsQuery>;
export type GetReportsByParamsLazyQueryHookResult = ReturnType<typeof useGetReportsByParamsLazyQuery>;
export type GetReportsByParamsQueryResult = Apollo.QueryResult<GetReportsByParamsQuery, GetReportsByParamsQueryVariables>;
export const GetListUserDocument = gql`
    query getListUser {
  getListUser {
    id
    username
    firstname
    lastname
    enabled
    workspace {
      id
      slug
    }
    region {
      regionId
    }
    medOrg {
      medOrgId
    }
    doctor {
      doctorId
    }
  }
}
    `;

/**
 * __useGetListUserQuery__
 *
 * To run a query within a React component, call `useGetListUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetListUserQuery(baseOptions?: Apollo.QueryHookOptions<GetListUserQuery, GetListUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListUserQuery, GetListUserQueryVariables>(GetListUserDocument, options);
      }
export function useGetListUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListUserQuery, GetListUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListUserQuery, GetListUserQueryVariables>(GetListUserDocument, options);
        }
export type GetListUserQueryHookResult = ReturnType<typeof useGetListUserQuery>;
export type GetListUserLazyQueryHookResult = ReturnType<typeof useGetListUserLazyQuery>;
export type GetListUserQueryResult = Apollo.QueryResult<GetListUserQuery, GetListUserQueryVariables>;
export const GetUserDocument = gql`
    query getUser($userId: Int!) {
  getUser(userId: $userId) {
    id
    username
    firstname
    lastname
    enabled
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const SaveUserDocument = gql`
    mutation saveUser($user: UserInput!) {
  saveUser(user: $user) {
    id
    username
    firstname
    lastname
    enabled
  }
}
    `;
export type SaveUserMutationFn = Apollo.MutationFunction<SaveUserMutation, SaveUserMutationVariables>;

/**
 * __useSaveUserMutation__
 *
 * To run a mutation, you first call `useSaveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserMutation, { data, loading, error }] = useSaveUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useSaveUserMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserMutation, SaveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserMutation, SaveUserMutationVariables>(SaveUserDocument, options);
      }
export type SaveUserMutationHookResult = ReturnType<typeof useSaveUserMutation>;
export type SaveUserMutationResult = Apollo.MutationResult<SaveUserMutation>;
export type SaveUserMutationOptions = Apollo.BaseMutationOptions<SaveUserMutation, SaveUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($userId: Int!) {
  deleteUser(userId: $userId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;