import React from 'react';
import {InputAdornment, TextField} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';

interface CustomSearchDatatableProps {
    onSearch: any;
    onClear: any;
}

function CustomSearchDatatable({onSearch, onClear}: CustomSearchDatatableProps) {
    const [searchText, setSearchText] = React.useState('');

    const handleOnSearch = () => {
        searchText.length > 2 && onSearch(searchText);
    };

    const handleOnClear = () => {
        onClear();
    };

    return (
        <Grid2 container direction={"row"} spacing={1}>
            <Grid2 alignSelf={'center'}>
                <Typography>Поиск</Typography>
            </Grid2>
            <Grid2 sx={{flexGrow: 1}}>
                <TextField
                    size={'small'}
                    autoFocus
                    required
                    margin="dense"
                    fullWidth
                    sx={{my: 1, bgcolor: "white", borderRadius: 1}}
                    value={searchText}
                    onChange={(event) => setSearchText(event.currentTarget.value)}
                    onKeyDown={(event) => {
                        event.key === 'Enter' && handleOnSearch();
                    }}
                    InputProps={{
                        endAdornment:
                            <InputAdornment
                                position="end"
                                style={{height: '100%'}}
                            >
                                <ClearIcon
                                    style={{marginLeft: '8px', cursor: 'pointer'}}
                                    onClick={() => {
                                        setSearchText('');
                                        handleOnClear();
                                    }}
                                    fontSize={'small'}
                                    color={'primary'}
                                />
                                <SearchIcon
                                    style={{marginLeft: '8px', cursor: 'pointer'}}
                                    onClick={handleOnSearch}
                                    fontSize={'small'}
                                    color={searchText.length > 2 ? 'primary' : 'disabled'}
                                />
                            </InputAdornment>
                    }}
                />
            </Grid2>
        </Grid2>
    );
}

export default CustomSearchDatatable;

