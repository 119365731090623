import {IStatsMenuItem} from "../../models/stats/IStatsMenuItem";
import {STATS_MENU_CONFIG} from "../../config/stats/statsMenuConfig";
import {Link, useLocation, useNavigate} from "react-router-dom";
import List from '@mui/material/List';
import {Collapse, ListItem, ListItemButton, ListItemText} from "@mui/material";
import React from "react";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import "./SideMenu.css";
import Grid2 from "@mui/material/Unstable_Grid2";


interface ISideMenuProps {

}

const SideMenu = ({}: ISideMenuProps) => {

    const navigate = useNavigate();
    const location = useLocation();

    const [open, setOpen] = React.useState([true, true]);

    const handleClick = (index: number) => {
        setOpen((open: boolean[]) => open.map((state: boolean, idx: number) => idx === index ? !state : state));
    };

    return <Grid2 sx={{p: "0"}}>

        {  <List component={"nav"}>{
            STATS_MENU_CONFIG.map((menuItem: IStatsMenuItem, index: number) =>
                <div key={menuItem.path}>
                    <ListItem>
                        <ListItemButton onClick={() => handleClick(index)} sx={{borderRadius: "12px"}}>
                            <ListItemText primary={
                                menuItem.children?.length
                                    ? <span>{menuItem.name}</span>
                                    : <Link to={menuItem.path}>{menuItem.name}</Link>
                            }/>
                            {menuItem.children?.length ? (open[index] ? <ExpandLess/> : <ExpandMore/>) : null}
                        </ListItemButton>
                    </ListItem>

                    {menuItem.children?.length ? menuItem.children.map((childItem: IStatsMenuItem) =>
                        <Collapse key={"collapse" + childItem.path} in={open[index]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <ListItem key={menuItem.path + childItem.path}>
                                    <ListItemButton
                                        sx={{pl: 4, borderRadius: "8px"}}
                                        onClick={() => navigate(menuItem.path + "-" + childItem.path)}
                                        selected={location.pathname === "/stats/" + menuItem.path + "-" + childItem.path}
                                    >
                                        <ListItemText primary={childItem.name} />
                                    </ListItemButton>
                                </ListItem>

                            </List>
                        </Collapse>
                    ) : null}
                </div>
            )
        }</List>}

    </Grid2>
}

export default SideMenu;