import SideMenu from "./SideMenu";
import {useOutlet} from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import React from "react";


const StatsPage = () => {

    const outlet = useOutlet();

    return <>
        <Grid2 container sx={{
            flexDirection: "row",
            flexWrap: "nowrap",
            pt: 3, pb: 3, pl: 0, pr: 3,
            bgcolor: "#e8f3f5",
            height: "calc(100vh - 64px)",
            gap: 3,
        }}>
            <Grid2 sx={{ width: '18%', minWidth: "240px", bgcolor: "white", borderRadius: "0 12px 12px 0"}}>
                <SideMenu></SideMenu>
            </Grid2>

            <Grid2 sx={{
                flexGrow: 1,
                bgcolor: "white",
                p: 3,
                borderRadius: "12px",
                maxWidth: "max(658px, calc(82% - 24px))",
                overflowY: "auto",
                width: "calc(82% - 48px)"
            }}>
                {outlet || <span>Выберите раздел</span>}
            </Grid2>

        </Grid2>
    </>;
}

export default StatsPage;