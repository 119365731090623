import {LogLevel} from './app/services/LoggerService';

/** The App environment */
export type Environment = 'DEVELOPMENT' | 'PRODUCTION';

export const APP_ENV: Environment = process.env.REACT_APP_APP_ENV === 'PRODUCTION' ? 'PRODUCTION' : 'DEVELOPMENT';

export const LOG_LEVEL: LogLevel = APP_ENV === 'PRODUCTION' ? 'WARN' : 'LOG';
export const SHOW_DEBUG: boolean = process.env.REACT_APP_SHOW_DEBUG === 'TRUE';
export const LOG_DEBUG: boolean = APP_ENV !== 'PRODUCTION' && SHOW_DEBUG;
