export default class WorkspaceNamingService {
    private static readonly WS_NAMES: Map<string, string> = new Map<string, string>([
        ["llo",  "Модуль ЛЛО"],
        ["HM_region_filter_related", "Модуль Контроля Качества"],
        ["region", "Модуль ДН"],
        ["after_events", "Проактивное наблюдение после событий"],
        ["emed_after_events", "(emed) Проактивное наблюдение после событий"],
        ["proactive_dn", "Модуль проактивного ДН пациентов с ХСН"],
        ["emed_proactive_dn", "(emed) Модуль проактивного ДН пациентов с ХСН"],
        ["AZ", "АстраЗенека"]
    ]);

    public static getWsName(slug: string) {
        return WorkspaceNamingService.WS_NAMES.get(slug) || slug;
    }
}