import React, {Fragment, useState} from 'react';
import {
    GridColDef,
    GridRenderCellParams,
    GridToolbarExport,
    GridToolbarContainer,
    gridClasses
} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import {observer} from 'mobx-react';
import UsersStore from '../../stores/users';
import {IUser} from '../../models/users/IUser';
import {useEffectOnce} from '../../hooks/useEffectOnce';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import BoltIcon from '@mui/icons-material/Bolt';
import UserDialog from './UserDialog';
import CustomSearchDatatable from './CustomSearchDatatable';
import Grid2 from '@mui/material/Unstable_Grid2';
import {X_DATA_GRID_LOCALETEXT} from '../../config/xdatagrid';
import {StyledDataGrid} from '../common/StyledDataGrid';
import {useGetListUserLazyQuery} from '../../../graphql/generated/graphql';
import {Add} from '@mui/icons-material';
import {rootStore} from '../../stores/root';
import {logger} from '../../services/LoggerService';
import {Chip, List, ListItem, ListItemText, Typography} from "@mui/material";
import {IWorkspace} from "../../models/IWorkspace";
import WorkspaceNamingService from "../../services/WorkspaceNamingService";
import Tooltip from '@mui/material/Tooltip';
import {StyledBadge} from "../common/StyledBadge";
import {IUserRegion} from "../../models/users/IUserRegion";
import NamesResolveService from "../../services/NamesResolveService";
import {IUserMedOrg} from "../../models/users/IUserMedOrg";
import {IUserDoctor} from "../../models/users/IUserDoctor";
import ResourceTreeService from "../../services/ResourceTreeService";

const CustomToolbar = () => {
    const onCreateUser = () => {
        const user: IUser = {
            password: '',
            username: '',
            firstname: '',
            lastname: '',
            id: null,
        } as any;
        rootStore!.usersStore!.setOpenUserDialog(true, user);
    }

    return (
        <GridToolbarContainer sx={{justifyContent: 'end'}}>
            <GridToolbarExport
                variant={'contained'}
                lang={'ruRU'}
                csvOptions={{delimiter: ';', allColumns: true}}
                printOptions={{disableToolbarButton: true}}
            />
            <Button
                variant={'contained'}
                sx={{ml: 1}}
                onClick={onCreateUser}
                size={'small'}
            >
                <Add
                    fontSize="small"
                    sx={{mr: 1}}
                />
                Создать
            </Button>
        </GridToolbarContainer>
    );
};

const UsersPage = observer(({store}: {store: UsersStore}) => {
    
    const [datatableData, setDatatableData] = useState<IUser[]>([]);

    const [getListUser, { loading: usersLoading, error: usersError, data: usersData }] = useGetListUserLazyQuery();

    const loadUsers = () => {
        getListUser()
            .then((result) => {
                let users = result.data?.getListUser as IUser[] || [];

                Promise.allSettled([
                    NamesResolveService.getRegionLabels(
                        [...new Set(users.map((user: IUser) => user.region || [])
                        .reduce((accumulator: string[], currentValue: IUserRegion[]) =>
                                accumulator.concat(currentValue.map(region => region.regionId)), []
                        ))]
                    ),
                    NamesResolveService.getMedOrgLabels(
                        [...new Set(users.map((user: IUser) => user.medOrg || [])
                        .reduce((accumulator: string[], currentValue: IUserMedOrg[]) =>
                                accumulator.concat(currentValue.map(medOrg => medOrg.medOrgId)), []
                        ))]
                    ),
                    NamesResolveService.getDoctorLabels(
                        [...new Set(users.map((user: IUser) => user.doctor || [])
                        .reduce((accumulator: string[], currentValue: IUserDoctor[]) =>
                                accumulator.concat(currentValue.map(doctor => doctor.doctorId)), []
                        ))]
                    ),
                ]).then(([regionLabels, medOrgLabels, doctorLabels]) => {
                    users = users.map((user: IUser) => {
                        if (regionLabels.status === 'fulfilled') {
                            user.region.map((region: IUserRegion) => {
                                region.slug = ResourceTreeService.getLabelById(region.regionId, regionLabels.value);
                            })
                        }
                        if (medOrgLabels.status === 'fulfilled') {
                            user.medOrg.map((medOrg: IUserMedOrg) => {
                                medOrg.slug = ResourceTreeService.getLabelById(medOrg.medOrgId, medOrgLabels.value);
                            })
                        }
                        if (doctorLabels.status === 'fulfilled') {
                            user.doctor.map((doctor: IUserDoctor) => {
                                doctor.slug = ResourceTreeService.getLabelById(doctor.doctorId, doctorLabels.value);
                            })
                        }
                        return user;
                    });
                    return users;

                }).finally(() => {
                    setDatatableData(users);
                })
            })
            .catch((reason) => {
                logger.debug('+++ getListUser() +++ reason:', reason);
            });
    }

    useEffectOnce(() => {
        loadUsers();
    });

    const onEditUser = (params: GridRenderCellParams) => {
        store.setOpenUserDialog(true, params.row);
    }

    const onUsersSearch = (searchText: string) => {
        const _users: IUser[] = usersData?.getListUser!.filter((iUser) => {
            return (
                searchText === undefined
                ||
                (iUser!.username || '').toLowerCase().includes(searchText.toLowerCase())
                ||
                (iUser!.firstname || '').toLowerCase().includes(searchText.toLowerCase())
                ||
                (iUser!.lastname || '').toLowerCase().includes(searchText.toLowerCase())
            );
        }) as IUser[];
        setDatatableData(_users);
    }

    const onUsersSearchClear = () => {
        setDatatableData(usersData?.getListUser as IUser[] || []);
    }

    const renderTooltip = (list: Array<IUserRegion | IUserMedOrg | IUserDoctor>, label: string) => {
        return (list.length
            ? <Tooltip
                title={
                    <List dense={true}>
                        {list.map((item) => <ListItem key={item.userId + item.slug!}>
                            <ListItemText primary={item.slug} />
                        </ListItem>)}
                    </List>
                }
                arrow
                placement="top"
                sx={{cursor: "pointer"}}>
                <StyledBadge badgeContent={list.length} color="primary">
                    <Typography variant="body1">{label}</Typography>
                </StyledBadge>
            </Tooltip> : "")
    }

    const renderResourcesCell = (params: GridRenderCellParams) => {

        const user: IUser = params.row;

        if (!user.medOrg.length && !user.region.length && !user.doctor.length) {
            return <Typography>нет ограничений прав</Typography>
        } else {
            return <div style={{display: "flex", gap: "32px"}}>
                {renderTooltip(user.region, "Регион")}
                {renderTooltip(user.medOrg, "МО")}
                {renderTooltip(user.doctor, "Врач")}
            </div>
        }
    }

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: '',
            width: 50,
            minWidth: 0,
            headerClassName: 'actionsHeader',
            sortable: false,
            disableColumnMenu: true,
            disableReorder: true,
            renderHeader: (params) => (
                <Fragment>
                    <BoltIcon fontSize={'small'} />
                </Fragment>
            ),
            renderCell: (params: GridRenderCellParams) => (
                <Fragment>
                    <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => onEditUser(params)}
                    >
                        <EditIcon fontSize="inherit" />
                    </IconButton>
                </Fragment>
            ),
        },
        { field: 'username', headerName: 'Логин', width: 222 },
        { field: 'firstname', headerName: 'Имя', width: 222 },
        {field: 'medOrg', headerName: 'Права на ресурсы', width: 222, renderCell: renderResourcesCell},
        { field: 'workspace', headerName: 'Доступные модули', flex: 1,
            renderCell: (params: GridRenderCellParams) => (
                <div style={{display: "flex", flexWrap: "wrap", gap: "6px"}}>{
                    params.row.workspace.length ? params.row.workspace.map((item: IWorkspace) => <Chip
                        key={item.id}
                        size={'small'}
                        label={WorkspaceNamingService.getWsName(item.slug)}
                        color='primary'
                        variant="outlined"
                    />) : <Typography>все модули</Typography>
                }</div>
            ),
        },
    ];

    return (
        <Fragment>
            <Grid2 container sx={{p: 3, bgcolor: "#e8f3f5"}} direction={'column'}>
                <Grid2 sx={{mb: 1}}>
                    <CustomSearchDatatable onSearch={onUsersSearch} onClear={onUsersSearchClear} />
                </Grid2>
                <Grid2 sx={{ height: 'calc(100vh - 176px)', width: '100%'}}>
                    <StyledDataGrid
                        components={{Toolbar: CustomToolbar}}
                        loading={usersLoading}
                        localeText={X_DATA_GRID_LOCALETEXT}
                        paginationMode={'client'}
                        pageSize={store.pageSize}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        rows={datatableData}
                        columns={columns}
                        page={store.page}
                        onPageChange={(page) => store.setPage(page)}
                        onPageSizeChange={(pageSize, details) => {
                            store.setPageSize(pageSize);
                        }}
                        onSortModelChange={(model, details) => {
                            store.setSortModel(model);
                        }}
                        hideFooterSelectedRowCount={true}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        getRowHeight={() => 'auto'}
                        sx={{
                            [`& .${gridClasses.cell}`]: {
                                py: 1,
                            },
                        }}
                    />
                </Grid2>
            </Grid2>
            {store.lazyOpenUserDialog && <UserDialog store={store} reloadUsersCallback={loadUsers} />}

        </Fragment>
    );
});

export default UsersPage;
