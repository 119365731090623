import UsersStore from '../stores/users';
import {IUser} from '../models/users/IUser';

export default class FirestoreService {
    static isAdminUser = async (user: IUser): Promise<boolean> => {
        // const adminQuery = doc(GlobalFirebase.dbFirebase, 'admins', user.uid);
        // const adminQuerySnapshot = await getDoc(adminQuery);
        return new Promise((resolve, reject) => {
            resolve(true);
        });
    }

    static saveUser = async (user: IUser): Promise<IUser | null> => {
        return new Promise((resolve, reject) => {
            resolve(user);
        });
    }

    static getUser = (uid: string): Promise<IUser | null> => {
        return new Promise((resolve, reject) => {
            resolve(null);
        });
    }

    static loadUsers = async (usersStore: UsersStore) => {
        usersStore.isLoading = true;
        usersStore.setUsers([]);
        usersStore.isLoading = false;
    }

    static searchUsers = async (usersStore: UsersStore, searchText?: string) => {
        usersStore.isLoading = true;
        usersStore.setUsers([]);
        usersStore.isLoading = false;
    }
}
